import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { CalendarContext, KpiApiContext, ThemeContext } from '@flexo/providers';
import { formatDate } from '../helpers';
import styles from './battery-chart.module.scss';



function CombinedBatteryChart(props: any) {
  const { t } = useTranslation();
  const { ThemeStore } = useContext(ThemeContext);
  const { kpiApiStore } = useContext(KpiApiContext);
  const { calendarStore } = useContext(CalendarContext);

  const [unit, setUnit] = useState('');
  const [chartData, setChartData] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState<string | null>(null);
  const [ activeDataSets, setActiveDataSets ] = useState<any[]>([  ]);
  
  const rawData = kpiApiStore?.[props?.context]?.['1hour'] || [];

  const CustomizedDot = ( _props) => {
    const { cx, cy, payload, index } = _props;
  
    return (
      <circle
        key={payload.timestamp}
        cx={cx}
        cy={cy}
        r={ (activeIndex !== null && parseInt(activeIndex) === index) ? 8 : 5}  // se stiamo hoverando, r=5, altrimenti 1
        fill={ (activeIndex !== null && parseInt(activeIndex) === index) ? `${ThemeStore?.colors[props.colors[1].color]}${props.colors[1].colorShade}` : 'transparent'}
        stroke="none"
        onMouseEnter={() => setActiveIndex(`${index}`)}
        onMouseLeave={() => setActiveIndex(null)}
        style={{ transition: 'r 0.2s ease' }}
      />
    );
  };

  
  // Handle mouse hover to set active bar
  const handleMouseMove = (index) => {
      setActiveIndex(`${index}`); // Only set active index if no bar is selected


  };

  const handleMouseLeave = () => {
    setActiveIndex(null); // Reset active index on mouse leave
  };

  // Click handler to set the selected bar
  const handleBarClick = (index) => {
    setActiveIndex(`${index}`); // Only update if a new bar is selected
    
  };

  function formatButtonValue(fieldName: string|null, buttonString: string, value: number|null) {
    let formattedValue = `${buttonString}`;

    if (value === null && buttonString) {
      if (fieldName === 'soc_battery') {
        return `${buttonString} (%)`;
      }
      return formattedValue;
    }
    
    switch (fieldName) {
      case 'battery_net_flow':
        formattedValue = `${buttonString} | ${value} ${unit}`;
        break;
      case 'soc_battery':
        formattedValue = `${buttonString} | ${value} %`;
        break;
      default:
      break;
    }

    return formattedValue

  }

  function getSymmetricTicks(data: any ): number[] {
    // Estrai tutti i valori di battery_net_flow
    const values = data.map(d => d?.[props.db_fields?.[0] || 0]);
  
    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);
  
    const maxAbs = Math.max(Math.abs(maxValue), Math.abs(minValue));
  
    const roundedMaxAbs = Number(maxAbs.toFixed(2));
  
    return [
      roundedMaxAbs,
      Number((roundedMaxAbs / 2).toFixed(2)),
      0,
      Number((-roundedMaxAbs / 2).toFixed(2)),
      -roundedMaxAbs,
    ];
  }

  function getFillColor(index: any, colorIndex: number): string {
    
    if (activeIndex === null) {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}${props.colors[colorIndex].colorShade}`
    } else if (parseInt(activeIndex) === parseInt(index)) {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}${props.colors[colorIndex].colorShade}`
    } else {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}60`
    }

  }

  

  useEffect(() => {

    // Crea i dati per Recharts
    const _chartData = rawData.map((item: any) => {
      const formattedData: any = {
        timestamp: formatDate(item.timestamp, calendarStore.currentlySelectedDateType),
      };

      props?.db_fields.forEach((field, index) => {
        const kpiValue = item?.kpi.find((datum: any) => datum.name === field);
        if (kpiValue) {
          formattedData[field] = kpiValue.value;
        }
      });

      return formattedData;
    });

    setChartData(_chartData);

    // Imposta l'unità di misura
    if (props?.db_fields?.length > 0) {
      const firstField = props?.db_fields[0];
      const firstItem = rawData?.[0];
      if (firstItem) {
        const kpiValue = firstItem.kpi.find((datum: any) => datum.name === firstField);
        setUnit(kpiValue?.unit || '');
      }

      if (calendarStore.currentlySelectedDateType === 'day' && props?.context === 'site') {
        setActiveDataSets(Array.from({ length: props?.db_fields?.length }, (_) => true))
      }

    }
  }, [kpiApiStore?.[props?.context]?.['1hour'], props.db_fields, calendarStore.currentlySelectedDateType]);

  // function getOppositeValues() {

  //   const values = (chartData || []).reduce((prev, next) => ( 
  //     [
  //       ...prev,
  //       next[props.db_fields[0]],
  //     ]
  //   ), [])

  //   let maxValue = Math.max(...values);
  //   let minValue = Math.min(...values);


  //   if (maxValue <= 0) {
  //     maxValue = 0;
  //   }

  //   if (minValue < 0 && maxValue === 0) {
  //     maxValue = minValue * -1;
  //   }

  //   if (minValue >= 0 && maxValue > 0) {
  //     minValue = maxValue * -1;
  //   }
  //   return [minValue,0,  maxValue];
  // }

  function toggleDataSets(index: number) {

    if (activeDataSets?.length < 2) { return }

    if (activeDataSets.length === 2 && activeDataSets.includes( false ) && activeDataSets.indexOf( true ) === index ) {
      // setActiveDataSets([true, true]);
      return;
    }

    const newActiveDataSets = [...activeDataSets];
    newActiveDataSets[index] = !newActiveDataSets[index];
    setActiveDataSets(newActiveDataSets);
  }
  
  const getInterval = () => {
    switch (calendarStore.currentlySelectedDateType) {
      case 'day':
      case 'week':
      case 'month':
        return 6;
      case 'year':
        return 2;
      default:
        return 0;
    }
  };


  return (
    <div
      id="CombinedBatteryChart__Wrapper"
      className={styles.CombinedBatteryChart__Wrapper}
    >
      <div className={ activeIndex ? styles.CombinedBatteryChart__Title__Active : styles.CombinedBatteryChart__Title}>

        {(activeIndex && rawData?.[activeIndex]?.timestamp) &&
          <div
            key={`BatteryChart__TimeStamp`}
            className={  styles.CombinedBatteryChart__TimeStamp}
          >
           {formatDate(rawData?.[activeIndex]?.timestamp, calendarStore.currentlySelectedDateType)}
          </div>
        }

        {t(props.title)}

        {/* {(activeIndex && chartData?.[activeIndex]?.[props?.db_fields?.[0]]) &&
          <div
            key={`BatteryChart__Total`}
            className={styles.CombinedBatteryChart__Total}
          >
            {chartData?.[activeIndex]?.[props?.db_fields?.[0]]} { unit}
          </div>
        } */}

        

      </div>
      <div className={styles.CombinedBatteryChart__Buttons__Wrapper}>
        {props.buttons.map((button: any, index: number) => {
          if (
            chartData.length > 0
          ) {

            if (index > 0 && (calendarStore.currentlySelectedDateType !== 'day')) {
              return ''
            }

            return (
              <div
                key={`BatteryChart__${index}__button__${button}`}
                className={styles.CombinedBatteryChart__Button}
                onClick={() => toggleDataSets(index)}
                style={{
                  opacity: (activeDataSets[index] || (activeDataSets?.length) === 0) ? 1 : 0.5,
                  cursor: (activeDataSets?.length < 2) ? 'default': 'pointer',
                }}
              >
                
                {
                  formatButtonValue(
                  props?.db_fields?.[index],
                  t(button),
                  (chartData?.[activeIndex as any]?.[props.db_fields?.[index]] || null)
                  )
                }
                
                  
                <div
                  className={styles.CombinedBatteryChart__Button__Underline}
                  style={{
                    backgroundColor: `${
                      ThemeStore?.colors[props.colors[index].color]
                    }${props.colors[index].colorShade}`,
                  }}
                ></div>
              </div>
            );
          } else {
            return null;
          }
        })}

      </div>

      {chartData.length > 0 && (
        <div className={styles.CombinedBatteryChart__Chart__Wrapper}>
          <div
            className={`${styles.CombinedBatteryChart__Chart__Unit} ${styles.CombinedBatteryChart__Chart__Unit__Left}`}
          >
            {unit}
          </div>
          <ResponsiveContainer width="100%" height={310}>
            <ComposedChart
              data={chartData}
              margin={{ top: 20, right: -0, left: -15, bottom: 0 }}
              onMouseLeave={handleMouseLeave}
            >
              {/* <CartesianGrid stroke="#f5f5f5" vertical={false} /> */}
              <XAxis
                dataKey="timestamp"
                tick={{ fontSize: 12, fontFamily: 'Flexo light', color: ThemeStore?.colors?.grey }}
                ticks={ chartData.map( (_, index ) => formatDate(rawData?.[index]?.timestamp, calendarStore.currentlySelectedDateType)) }
                interval={getInterval()}
                axisLine={false}
                tickLine={false}
              />
              <CartesianGrid
                strokeDasharray="0"
                vertical={false}
                horizontalValues={[0]}
                stroke={ ThemeStore?.colors?.gridColor }
                strokeWidth={0.5}
              />
              <YAxis
                yAxisId="left"
                orientation="left"
                tick={{ fontSize: 12, fontFamily: 'Flexo light', color: ThemeStore?.colors?.grey }}
                axisLine={true}
                tickLine={false}
                // domain={['auto', 'auto']}
                // domain={getOppositeValues()}
                ticks={getSymmetricTicks(chartData)}
                tickMargin={10}
                strokeWidth={0.1}
                
              />
              
              {calendarStore.currentlySelectedDateType === 'day' && props?.context === 'site' && (
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  tick={{ fontSize: 12, fontFamily: 'Flexo light', color: ThemeStore?.colors?.grey }}
                  domain={[0, 100]}
                  axisLine={true}
                  tickLine={false}
                  strokeWidth={0.1}
                />
              )}
              {/* <Tooltip /> */}
              <Bar
                yAxisId="left"
                dataKey={props.db_fields[0]}
                // fill={getFillColor()}
                hide={ activeDataSets.length === 0 ? false : !activeDataSets?.[0] || false }
                onClick={(_, i) => handleBarClick(i )} // Click handler for bar
                onMouseMove={(_, i) => handleMouseMove(i)} // Handle mouse hover
                radius={[2, 2, 2, 2]}
              >
                { 
                  chartData.map((entry, index) => (
                    <Cell key={ uuidv4()} fill={ getFillColor( index, 0 ) } />
                    
                  ) )
                }
                  
                </Bar>
              {calendarStore.currentlySelectedDateType === 'day' && props?.context === 'site' && (
                <Line
                  
                  hide={!activeDataSets[1]}
                  yAxisId="right"
                  type="monotone"
                  dataKey={ props.db_fields[1] }
                  stroke={`${ThemeStore?.colors[props.colors[1].color]}${props.colors[1].colorShade}`}
                  strokeWidth={3}
                  dot={CustomizedDot}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <div
            className={`${styles.CombinedBatteryChart__Chart__Unit} ${styles.CombinedBatteryChart__Chart__Unit__Right}`}
          >
            {calendarStore.currentlySelectedDateType === 'day' && props?.context === 'site' ? '%' : ''}
          </div>
        </div>
      )}
    </div>
  );
}

export default CombinedBatteryChart;
