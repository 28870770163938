import { useContext, useEffect } from 'react';

import { Calendar } from '@flexo/molecules';
import styles from './header.module.scss';
import {
  DataContext,
  MetaDataApiContext,
  ModalContext,
  ModalTypes,
  ModulesContext,
  UserContext,
} from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import React from 'react';
import { Dropdown, Search } from '@flexo/atoms';
import { SafeArea } from 'capacitor-plugin-safe-area';

function Header(props) {
  const {
    Header__Title,
    Header__Actions,
    Header__Actions__Button,
    Header__Title__Text,
    Header__Heading,
    Header__Calendar,
  } = styles;
  const [loading, setLoading] = React.useState(true);

  const { modalStore, setModalStore } = useContext(ModalContext);
  const { dataStore } = useContext(DataContext);
  const { userStore } = useContext(UserContext);
  const { modulesStore } = useContext(ModulesContext);

  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  const onClickProfile = () => {
    setModalStore({
      type: ModalTypes.OpenModal,
      payload: {
        value: {
          type: 'PROFILE',
          visible: true,
          show: true,
          props: { ...props },
          content: 'PROFILE',
        } as any,
      },
    });
  };
  
  const visibleModule = modulesStore.modules.find(
    (module) => module.visible
  )?.name;

  useEffect(() => {

    const fetchInsets = async () => {
      try {
        const { insets } = await SafeArea.getSafeAreaInsets();
        // setInsets(insets);
      } catch (error) {
        console.error('Error fetching safe area insets:', error);
      }
    };

    fetchInsets();
  }, []);
  return (
    <div
      className={` ion-safe-area ${styles.Header} ${
        dataStore?.navigation?.module !== 'household'
          ? styles.Header__Community
          : ''
      } 
     `}
    >
      <div className={Header__Heading}>
        {/* {JSON.stringify(metaDataApiStore.selectedEntity)}
        {JSON.stringify(userStore.user)} */}

        <div className={`${Header__Title} heading1`}>
          {visibleModule === 'household' ? <Search /> : <div>Comunita</div>}
        </div>

        <div className={Header__Actions}>
          {/* <button
            className={Header__Actions__Button}
            onClick={onClickNotifications}
          >
            <IconWrapper iconName="notifications" />
          </button> */}
          <button
            className={Header__Actions__Button}
            onClick={() => onClickProfile()}
            disabled={(modalStore?.modal as any).open === true}
          >
            <IconWrapper iconName="user_solid" />
          </button>
        </div>
      </div>
      <div className={Header__Calendar}>
        <Calendar />
      </div>
    </div>
  );
}

export default React.memo(Header);
