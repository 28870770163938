import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styles from './alert.module.scss';
import { useTranslation } from 'react-i18next';
import { AlertContext, AlertTypes, ThemeContext } from '@flexo/providers';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { IonContent, IonModal } from '@ionic/react';

import { AlertWrongSize } from './alert-components/alert-wrong-size';
import { AlertBannerConfirmChages } from './alert-components/alert-banner-confirm-chages';
import AlertWrongLogin from './alert-components/alert-wrong-login';
import AlertInvalidCredetials from './alert-components/alert-invalid-credentials';
import { AlertUnexpectedError } from './alert-components/alert-unexpected-error';
import AlertWrongParams from './alert-components/alert-wrong-params';
import AlertWrongRequestData from './alert-components/alert-wrong-request-data';
import BannerPasswordresetSuccess from './alert-components/banner-password-reset-success';
import BannerConfirmCredentialsChanges from './alert-components/banner-confirm-credentials-changes';
import { BannerCheckEmail } from './alert-components/banner-check-email';
import AlertSessionExpired from './alert-components/alert-session-expired';
import BannerLogoutSuccess from './alert-components/banner-logout-success';
import BannerCredentialsChangesSuccess from './alert-components/banner-credentials-chages-success';
import BannerChangeUserNameSuccess from './alert-components/banner-change-user-name-success';
import BannerConfirmCredentialsChangesMobile from './alert-components/banner-confirm-credentials-changes-mobile';
import { AlertNotAllowed } from './alert-components/alert-not-allowed';
import AlertWrongLoginMobile from './alert-components/alert-wrong-login-mobile';
import BannerPasswordresetSuccessMobile from './alert-components/banner-password-reset-success-mobile';

export const Alert: FC<any> = () => {
  const { t } = useTranslation();
  const { alertStore, setAlertStore } = useContext(AlertContext);

  const [insets, setInsets] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  const modal = useRef<HTMLIonModalElement>(null);

  const ENV = (import.meta as any).env;

  const hideAlert = () => {
    setAlertStore({
      type: AlertTypes.HideAlert,
      payload: null as any,
    });
    setTimeout(() => {
      setAlertStore({
        type: AlertTypes.ResetAlert,
        payload: null as any,
      });
    }, 1100);
  };

  useEffect(() => {
    const fetchInsets = async () => {
      try {
        const { insets } = await SafeArea.getSafeAreaInsets();
        setInsets(insets);
      } catch (error) {
        // console.error('Error fetching safe area insets:', error);
      }
    };

    fetchInsets();
  }, []);

  function getAlerContent() {
    let content = <></>;

    switch (alertStore.alert.type) {
      case 'ALERT_SCREEN_SIZE':
        content = <AlertWrongSize />;
        break;
      case 'ALERT_UNEXPECTED_ERROR':
        content = <AlertUnexpectedError />;
        break;
      case 'ALERT_NOT_ALLOWED':
        content = <AlertNotAllowed />;
        break;
      case 'ALERT_WRONG_REQUEST_DATA':
        content = <AlertWrongRequestData />;
        break;
      case 'ALERT_SESSION_EXPIRED':
        content = <AlertSessionExpired />;
        break;
      case 'ALERT_WRONG_LOGIN':
        content = <AlertWrongLogin />;
        break;
      case 'ALERT_WRONG_LOGIN__MOBILE':
        content = <AlertWrongLoginMobile />;
        break;
      case 'ALERT_INVALID_CREDENTIALS':
        content = <AlertInvalidCredetials />;
        break;
      case 'ALERT_WRONG_PARAMS':
        content = <AlertWrongParams />;
        break;
      case 'ALERT_BANNER_CONFIRM_CHANGES':
        content = <AlertBannerConfirmChages />;
        break;
      case 'BANNER_PASSWORD_RESET_SUCCESS':
        content = <BannerPasswordresetSuccess />;
        break;
      case 'BANNER_PASSWORD_RESET_SUCCESS_MOBILE':
        content = <BannerPasswordresetSuccessMobile />;
        break;
      case 'BANNER_REGISTRATION_SUCCESS':
        content = <BannerPasswordresetSuccess />;
        break;
      case 'BANNER_LOGOUT_SUCCESS':
        content = <BannerLogoutSuccess />;
        break;
      case 'BANNER_CONFIRM_CREDENTIALS_CHANGES':
        content = <BannerConfirmCredentialsChanges />;
        break;
      case 'BANNER_CONFIRM_CREDENTIALS_CHANGES_MOBILE':
        content = <BannerConfirmCredentialsChangesMobile />;
        break;
      case 'BANNER_CONFIRM_CREDENTIALS_CHANGES_SUCCESS':
        content = <BannerCredentialsChangesSuccess />;
        break;
      case 'BANNER_CHANGE_USERNAME_SUCCESS':
        content = <BannerChangeUserNameSuccess />;
        break;
      case 'BANNER_CHECK_EMAIL':
        content = <BannerCheckEmail />;
        break;
      default:
        content = <>wrong alert type</>;
        break;
    }

    return content;
  }

  // this works in reverse, if the alert type is not in the list, it will be dismissable
  function checkBackdropDismissCapability() {
    return ![
      'BANNER_CONFIRM_CREDENTIALS_CHANGES',
      'ALERT_SCREEN_SIZE',
      'ALERT_BANNER_CONFIRM_CHANGES',
    ].includes((alertStore?.alert?.type as string) || '');
  }

  return (
    <IonModal
      isOpen={alertStore.alert.visible}
      className={ ENV?.VITE_APP_IS_WEB === 'true' ? styles.Alert__Modal__Web : styles.Alert__Modal}
      onDidDismiss={() => hideAlert()}
      id="alert-modal-wrapper"
      ref={modal}
      backdropDismiss={checkBackdropDismissCapability()}
    >
      <IonContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            paddingTop: (insets.top || 0) + 'px',
          }}
        >
          {getAlerContent()}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default Alert;

// Alert component only for alerts
// Separated component for inputs, etc.
// Instead of global provider for alerts, just confirm the data by sending it from the triggere component(parent)
