import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate } from '../helpers';
import { useContext, useState, useCallback, useEffect } from 'react';
import { TooltipProps } from 'recharts';

import { CalendarContext, ThemeContext } from '@flexo/providers';
import styles from './area-chart.module.scss';
import { useTranslation } from 'react-i18next';

const ENV = (import.meta as any).env;

// Assuming that the value is a number and the name is a string:
const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 9999, // Ensure tooltip is on top
          position: 'absolute',
        }}
      >
        <p>{`Date: ${formatDate(label, 'DD/MM/YYYY')}`}</p>
        {payload.map((entry, index) => (
          <p key={`tooltip-${index}`}>{`${entry.name}: ${entry.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export const NewAreaChart = ({ colors, data, keys, title, unit }) => {
  const { calendarStore } = useContext(CalendarContext);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  const [tooltipData, setTooltipData] = useState(null); // Track active tooltip data

  // Track visibility of keys (true = visible, false = hidden)
  const [visibleKeys, setVisibleKeys] = useState({});

  useEffect(() => {
    if (keys && keys.length > 0) {
      setVisibleKeys(
        keys.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    }
  }, [keys]);

  const CustomTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={ props?.className?.includes('yAxis') ? x : x + 7.5}
        y={y}
        dy={8} // Adjust dy based on alignment
        textAnchor="middle"
        className="detail"
        fontSize={12}
        fill="#838B93"
        // style={{ margin: '3em' }}
      >
        {props?.className?.includes('yAxis')
          ? payload.value
          : formatDate(payload.value, calendarStore.currentlySelectedDateType)}
      </text>
    );
  };
  // Handle hover data without rerenders
  const handleMouseMove = useCallback((e) => {
    if (e && e.activePayload) {
      setTooltipData(e.activePayload); // Update tooltip data
    } else {
      setTooltipData(null); // Clear tooltip data when not hovering
    }
  }, []);

  const handleClick = (e) => {
    if (e && e.activeLabel) {
      setSelected(e.activeLabel); // Set the selected time (e.activeLabel is the clicked date)
    }
  };

  const toggleKeyVisibility = (key) => {
    const visibleKeysCount = Object.values(visibleKeys).filter(Boolean).length;

    // Prevent toggling if there is only one visible key
    if (visibleKeys[key] && visibleKeysCount === 1) {
      return;
    }

    // Toggle visibility
    setVisibleKeys((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle visibility
    }));
  };

  const getTitleDetail = () => {
    if (!selected) return;
    return formatDate(selected, calendarStore.currentlySelectedDateType);
  };

  const getStyling = (key, index) => {
    if (visibleKeys[key] === false) {
      return {
        borderBottom: `4px solid #F7F7F7`,
        borderColor: colors[index],
        borderRadius: '1px',
        color: '#505558',
        padding: '0em',
      };
    } else {
      return {
        borderBottom: `4px solid ${colors[index]}`,
        borderColor: colors[index],
        borderRadius: '1px',
        color: '#505558',
        padding: '0em',
      };
    }
  };
  const getInterval = () => {
    switch (calendarStore.currentlySelectedDateType) {
      case 'day':
        return 6;
      case 'week':
        return 6;
      case 'month':
        return 6;
      case 'year':
        return 2;
      default:
        return 0;
    }
  };

  return (
    <div
      className={
        ENV.VITE_APP_NAME === 'admin'
          ? styles.AreaChartWrapper__Web
          : styles.AreaChartWrapper__APP
      }
    >
      <div className={styles.Areachart__Header} style={{ marginBottom: '2em' }}>
        {title && (
          <div className={styles.GraphHeader__TitleContainer}>
            <div className={`${styles.GraphHeader__Title} heading4`}>
              {t(title)}{' '}
            </div>
          </div>
        )}
        <div style={{ marginLeft: '-.5em' }}>
          {keys.map((key, index) => {
            // Find the corresponding value from the tooltip payload (if available)
            const activeValue = (tooltipData as any)?.find(
              (item) => item.dataKey === key
            )?.value;
            return (
              <button
                key={key}
                className={`detail ${styles.Areachart__Key}`}
                style={getStyling(key, index)}
                onClick={() => toggleKeyVisibility(key)}
              >
                {`${t(key)} `}
                {activeValue !== undefined ? (
                  <strong>
                    | {activeValue} {unit}
                  </strong>
                ) : (
                  ''
                )}{' '}
              </button>
            );
          })}
        </div>
      </div>

      <ResponsiveContainer
        className={
          ENV.VITE_APP_NAME === 'admin'
            ? styles.AreaChartContaniner__Web
            : styles.AreaChartContaneiner__APP
        }
        width="100%"
        height={ENV.VITE_APP_NAME === 'admin' ? 310 : 236}
      >
        <AreaChart
          data={data}
          margin={{ top: 0, right: 30, left: -20, bottom: 0 }}
          onMouseMove={handleMouseMove} // Track mouse movement
          onMouseDown={handleClick} // Handle click event
        >
          <XAxis
            dataKey="date"
            tickFormatter={(tick) =>
              formatDate(tick, calendarStore.currentlySelectedDateType)
            }
            tick={<CustomTick />}
            axisLine={{ stroke: '#F7F7F7' }}
            tickLine={false}
            fontSize={12}
            interval={getInterval()}
            // padding={{ left: 10 }}
          />
          <YAxis
            tick={<CustomTick />}
            axisLine={{ stroke: '#F7F7F7' }}
            tickLine={false}
            fontSize={12}
            tickMargin={15}
            label={{
              value: unit,
              position: 'insideTop', // Adjust to "insideTop" to ensure it aligns inside at the top
              offset: 0, // Negative offset to move it above the axis line
              fill: '#838B93',
              className: 'detailBold',
              fontSize: 14,
              dx: 10, // This will move the label slightly to the right
            }}
            // padding={{ bottom: 10 }}
          />
          <Tooltip content={<></>} />
          {keys?.map(
            (key, index) =>
              visibleKeys[key] && ( // Conditionally render Area based on visibility
                <Area
                  key={index}
                  type="monotone"
                  dataKey={key}
                  stroke={colors[index]}
                  fill={colors[index]}
                  activeDot={{ r: 8 }} // Ensure active dot is displayed
                  strokeWidth={0}
                />
              )
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NewAreaChart;
