import React, { useState, useContext, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import {
  UserContext,
  ModulesTypes,
  ModulesContext,
  MetaDataApiContext,
  AlertContext,
  AlertTypes,
  UserTypes,
  MetaDataApiTypes,
} from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

let round = 0;

export interface CommunityInitProps {
  setModulesStore: any;
  metaDataApiStore: any;
  setMetaDataApiStore?: any;
  setAlertStore?: any;
  setUserStore?: any;
}

export async function CommunityInit(props: CommunityInitProps) {

  const {
    setModulesStore = null,
    metaDataApiStore = null,
    setMetaDataApiStore = null,
    setAlertStore = null,
    setUserStore = null,
  } = props;

  const idToken = await firebaseService.getIdToken();

  if (!idToken) {
    setAlertStore({
      type: AlertTypes.SetAlert,
      payload: {
        value: {
          type: 'ALERT_SESSION_EXPIRED',
        },
      },
    });

    setUserStore({
      type: UserTypes.SetUserLogOut,
      payload: {
        value: true,
      },
    });

    return;
  }

  const COMMUNITY_ID = metaDataApiStore.selectedEntityId;

  const [community] = await Promise.all([
    httpService
      .get({
        url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/community/${COMMUNITY_ID}`,
        // /hive/api/catalog/v1_3/community/{communityId}
        idToken,
      })
      .then((res: any) => {
        if (res.status === 200) {
          return res.data;
        } else {
          // console.warn({
          //   status: res.status,
          //   message: res.statusText,
          // });
          return null;
        }
      })
      .catch((err: any) => {

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR',
            },
          },
        });
        return null;
      }),
  ]);

  setMetaDataApiStore({
    type: MetaDataApiTypes.SetSelectedEntity,
    payload: {
      value: {
        selectedEntity: community,
        id: COMMUNITY_ID as string,
        entityType: metaDataApiStore?.entityType?.includes('community-')
          ? metaDataApiStore?.entityType
          : 'community',
      },
    },
  });
}

export const CommunityResolver = () => {
  const { t } = useTranslation();

  const { setUserStore } = useContext(UserContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setAlertStore } = useContext(AlertContext);

  useEffect(() => {
    if (round === 0) {
      CommunityInit({
        setModulesStore,
        metaDataApiStore,
        setMetaDataApiStore,
        setAlertStore,
        setUserStore,
      });
      round++;
    }
  }, [metaDataApiStore?.selectedEntityId]);

  return <></>;
};

export function navigateToCommunity(
  community: any,
  setMetaDataApiStore: any,
  setModulesStore: any,
  context: string | null = null
) {
  round = 0;

  const COMMUNITY_ID =
    typeof community === 'string'
      ? community
      : community?.flexoCommunityId || community?.communityId || community?.id;

  if (context) {
    setModulesStore({
      type: ModulesTypes.NavigateToCommunityContext,
      payload: {
        value: {
          id: COMMUNITY_ID,
          entity: `community-${context}`,
        },
      },
    });
  } else {
    setModulesStore({
      type: ModulesTypes.ActivateCommunityModule,
      payload: {
        value: COMMUNITY_ID,
      },
    });
  }

  setMetaDataApiStore({
    type: MetaDataApiTypes.SetSelectedEntity,
    payload: {
      value: {
        selectedEntity: typeof community === 'string' ? null : community,
        id: COMMUNITY_ID,
        entityType: context ? `community-${context}` : 'community',
      },
    },
  });
}
