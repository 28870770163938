
import React, { useContext, useEffect, useState, } from 'react';

import styles from './header-web.module.scss';
import { DataContext, MetaDataApiContext, ModulesContext } from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import {  ProfileButton, Search, navigateToCommunities, navigateToCommunity, navigateToMember } from '@flexo/atoms';

import './header-web.module.scss';
import { Autocomplete, Select } from '@mui/material';
import { Calendar } from '@flexo/molecules';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
  Header__Web__Center,
} = styles;

function MemberDataVisNavigation(props: any) {
  
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;
  const { t } = useTranslation()

  const communities = (metaDataApiStore?.entities as any)?.community || [];
  const members = (metaDataApiStore?.entities as any)?.member || [];

  function getMemberName() {
    let memberName = (metaDataApiStore?.selectedEntity as any)?.memberFirstName && (metaDataApiStore?.selectedEntity as any)?.memberLastName  ? `${(metaDataApiStore?.selectedEntity as any)?.memberFirstName} ${(metaDataApiStore?.selectedEntity as any)?.memberLastName}` :  null;
    
    const foundMember = members.find((member: any) => (member?.memberId === metaDataApiStore?.selectedEntityId ));
    
    if (!memberName) {
      memberName = foundMember?.memberFirstName && foundMember?.memberLastName ? `${foundMember?.memberFirstName} ${foundMember?.memberLastName}` : null;
    }

    return memberName  || 'Member name not found';

  }

  function getMemberCommunity() {
    let memberCommunityId = (metaDataApiStore?.selectedEntity as any)?.communityId || null;
    
    if (!memberCommunityId) {
      memberCommunityId = (metaDataApiStore?.entities as any)?.member?.find( (member: any) => member?.memberId === metaDataApiStore?.selectedEntityId )?.communityId || null
    }

    const foundCommunity = communities.find((community: any) => (community?.communityId === memberCommunityId ));

    if ( !foundCommunity) {
      return communities?.[0]?.communityId;
    } else {
      return foundCommunity;
    }

  }

  function getMemberCommunityName() {
    const foundCommunity = getMemberCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>

       
        
        <div className={Navigation}>
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunity( getMemberCommunity(), setMetaDataApiStore, setModulesStore)} >{ getMemberCommunityName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <label className={ `${styles.clickable}`} onClick={() => navigateToMember( (metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId), setMetaDataApiStore, setModulesStore)} >{ getMemberName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <Select
            className='site-navigation-select'
            id="site-navigation-select"
            MenuProps={{
              className: 'site-navigation-select-menu'
            }}
            defaultValue={metaDataApiStore?.entityType || null}
            IconComponent={() => <IconWrapper className={'site-navigation-select-icon'} iconName='chevron-down' />}
          >
            <option
              disabled={ metaDataApiStore?.entityType === 'member-energy' }
              onClick={() => {
              navigateToMember( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'energy' )
            }} value="member-energy">Energy</option>
            <option
              disabled={ metaDataApiStore?.entityType === 'member-economics' }
              onClick={() => {
              navigateToMember( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'economics' )
            }} value="member-economics">Economics</option>
          </Select>

          
      {/* {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          } */}
      
    </div>


      </div>

      <div className={Header__Web__Center}>
          <Calendar />
        </div>

      <div className={Header__Web__Right}>
        {
          searchBar &&
          <Search />
        }
        {
        profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(MemberDataVisNavigation);
