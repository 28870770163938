import { useTranslation } from 'react-i18next';
import styles from './../alert.module.scss';
import { errorParser, httpService, IconWrapper, REGEX } from '@flexo/general';
import { AlertContext, AlertTypes, UserContext } from '@flexo/providers';
import { useContext, useRef, useState } from 'react';
import Textfield from '../../textfield/textfield';
import Button from '../../button/button';
import { Player } from '@lottiefiles/react-lottie-player';
import { Icon } from '@mui/material';

const ENV = (import.meta as any).env;
const loader = '/loaderLottie.json';

export default function BannerConfirmCredentialsChangesMobile() {
  const { t } = useTranslation();
  const bannerContent = t('banners.confirm_credentials_changes', {
    returnObjects: true,
  }) as any;

  const formRef = useRef<any>(null);

  const { userStore } = useContext(UserContext);
  const [alertContent, setAlertContent] = useState<any>(
    t('alerts.wrong_request_data', { returnObjects: true })
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [fieldVisible, setFieldVisible] = useState<boolean>(false);
  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [showPasswordReset, setShowPasswordReset] = useState<boolean>(false);
  const [passwordField, setPasswordField] = useState<string>();
  const [icon, setIcon] = useState('visibility');

  const [inputType, setInputType] = useState({
    password: 'password',
    confirmPassword: 'password',
  });

  const { alertStore, setAlertStore } = useContext(AlertContext);

  async function handleInputChange(event: any) {
    if (showAlert === true && formError === true) {
      setShowAlert(false);
      setShowPasswordReset(false);
    }
    setPasswordField(event.target.value);

    setFormError(false);
  }

  async function manageAlertCallBack() {
    let result: any = null;
    if (alertStore?.alert?.callback) {
      result = await alertStore.alert.callback();

      setAlertStore({ type: AlertTypes.ResetAlert });
    }

    if (typeof result === 'number' && result > 200) {
      setFormError(true);

      if (result === 422) {
        setAlertContent(
          t('alerts.wrong_request_data', { returnObjects: true })
        );
      }

      setShowAlert(true);
    } else if (result === null) {
      setAlertStore({ type: AlertTypes.ResetAlert });
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_UNEXPECTED_ERROR',
          },
        },
      });
    }
  }

  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
    setIcon(icon === 'visibility' ? 'hide-visibility' : 'visibility');
  };

  async function checkPasswordValidity() {
    setSendingForm(true);

    const response = await httpService
      .post({
        url: `${ENV.VITE_APP_BASE_URL}/auth/v1_1/generate-signin-token`,
        data: {
          email:
            userStore?.user?.auth?.currentUser?.email ||
            userStore?.user?.tokenContent?.firebaseUserResult?.email,
          password: passwordField,
          tenant_id: ENV.VITE_APP_TENANT,
        },
      })
      .then((resp: any) => ({
        status: resp.status,
        statusText: resp?.statusText || 'Unknown status text',
        message: resp?.data?.message || 'Unknown message',
      }))
      .catch((error) => {
        // console.warn(error);
        return {
          status: error?.response?.status || 500,
          statusText: error?.response?.statusText || 'Unknown error',
          ...errorParser(error),
        };
      });

    if (response.status === 200) {
      await manageAlertCallBack();
    } else {
      setShowAlert(false);
      setFormError(true);
      setShowPasswordReset(true);
    }

    setSendingForm(false);
    setPasswordField('');
  }

  const onCancel = () => {
    setAlertStore({ type: AlertTypes.ResetAlert });
    setPasswordField('');
  };

  return (
    <div>
      <div
        id="confirm-credentials-changes-alert"
        className={styles.Alert__Confirm__Credentials__Changes}
        style={{ opacity: showAlert ? 1 : 0 }}
      >
        <div className={styles.Alert__Title}>{alertContent.title}</div>
        <div className={styles.Alert__Content}>{alertContent.description}</div>
      </div>

      <div
        id="banner-confirm-credentials-changes"
        className={styles.Banner__Confirm__Credentials__Changes}
      >
        <div className={styles.Banner__Title}>
          {bannerContent.title}
          {/* <div
            className={styles.Banner__Title__Icon__Close}
            onClick={() => setAlertStore({ type: AlertTypes.ResetAlert })}
          >
            <IconWrapper iconName="cross" />
          </div> */}
        </div>
        <div className={styles.Banner__Content}>
          {bannerContent.description}
        </div>

        <form
          id="banner-confirm-credentials-changes-form"
          className={styles.Banner__Content__Form}
        >
          <div className={styles.Banner__Content__Form__Input} ref={formRef}>
            <Textfield
              containedLabel={true}
              name={bannerContent.inputName}
              label={bannerContent.inputLabel}
              type={inputType.password}
              value={passwordField}
              placeholder={bannerContent.inputPlaceholder}
              error={formError}
              onPaste={(e) => false}
              onCopy={(e) => false}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
              autoComplete="off"
              onChange={(ev: any) => handleInputChange(ev)}
              icon={icon}
              onIconClick={togglePasswordVisibility}
              errorDescription={
                formError ? 'Inserisci una password valida' : undefined
              }
            />
          </div>
        </form>

        <div className={styles.Banner__Footer__Actions__Mobile}>
          <button
            className={`${styles.Banner__Footer__Actions__Mobile__Button} ${styles.Banner__Footer__Actions__Mobile__Button__Dismiss}`}
            onClick={() => onCancel()}
          >
            {bannerContent.dismiss}
          </button>

          <button
            className={`${styles.Banner__Footer__Actions__Mobile__Button} ${styles.Banner__Footer__Actions__Mobile__Button__Confirm}`}
            onClick={() => checkPasswordValidity()}
          >
            {sendingForm ? (
              <Player
                autoplay
                loop
                src={loader}
                style={{ height: 20, transform: 'scale(0.7)' }}
              />
            ) : (
              bannerContent.confirm
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
