import { MetaDataApiContext, ModulesContext } from "@flexo/providers";
import { Autocomplete, ListItem, Popper, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToCommunity } from "../resolvers/community-resolver";
import { navigateToMember } from "../resolvers/member-resolver";


import styles from './breadcrumbs-dropdown.module.scss'
import { IconWrapper } from "@flexo/general";
import { navigateToSite } from "../meta-resolvers/site-resolver";

export function CommunitiesDropDown() {
  const { t } = useTranslation();
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);

  const communities = ((metaDataApiStore?.communities as any)?.length > 0 ? metaDataApiStore?.communities : null) || (metaDataApiStore?.entities as any)?.communities || [];
  
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <Autocomplete
      disablePortal
      id="dropdown-communities"
      clearOnBlur={false}
      className={`${styles.BreadCrumb}`}
      options={communities || []}
      getOptionLabel={(option: any) => option.communityName}
      noOptionsText={
        <span className={`${styles.Autocomplete__List__Item}`}>
          {t('widgets.search.no_results')}
        </span>
      }
      popupIcon={
        <IconWrapper
          iconName={'chevron-right'}
          className={`${styles.BreadCrumb__Icon}`}
        />
      }
      renderOption={(props, option, { selected }) => (
        <ListItem
          {...props}
          className={`${ option?.communityId === metaDataApiStore?.selectedEntityId ? styles.Autocomplete__List__Item__Active : styles.Autocomplete__List__Item}`}
          value={option.value}
        >
          <span
            style={{ paddingLeft: 24, cursor: metaDataApiStore?.selectedEntityId !== option.communityId ? 'pointer': 'default' }}
            onClick={(ev: any) => {
              return metaDataApiStore?.selectedEntityId !== option.communityId
                ? navigateToCommunity(option, setMetaDataApiStore, setModulesStore)
                : null
            }}
          >
            {option?.communityName}
          </span>
        </ListItem>
      )}
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            placement="bottom-start"
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
              paddingTop: 36,
            }}
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          style={{
            width: (((searchTerm?.length || (metaDataApiStore?.selectedEntity as any)?.communityName?.length) * 10) + 10) || 0,
            minWidth: 100,
            maxWidth: 300
          }}
          className={ styles.BreadCrumb__Input }
          onChange={(ev: any) => (
            setSearchTerm(ev.target.value)
          )}
          placeholder={
            (metaDataApiStore?.selectedEntity as any)?.communityName ||
            (t('dropdowns.communities') as string)
          }
          label={
            (metaDataApiStore?.selectedEntity as any)?.communityName ||
            (t('dropdowns.communities') as string)
          }
        ></TextField>
      )}
    />
  );
}

export function MembersDropDown() {
  const { t } = useTranslation();
  const { setModulesStore } = useContext(ModulesContext);
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  const [searchTerm, setSearchTerm] = useState<string>('');

  function getMemberName(datum: any) {
    return `${datum?.memberFirstName} ${datum?.memberLastName}` || null
  }
  
  const parsedList = Object.entries(metaDataApiStore?.members || {}).reduce(
    (prev: any, next: any) => {
      return [
        ...prev,
        ...[...next[1].map((item: any) => ({ ...item, timeSpan: next[0] }))],
      ];
    },
    []
  );

  return (
    <Autocomplete
      disablePortal
      id="autocomplete-members"
      clearOnBlur={false}
      className={`${styles.BreadCrumb}`}
      options={parsedList || []}
      // groupBy={(option: any) => option.timeSpan}

      sx={{
        width: 'auto',
        '& .MuiAutocomplete-input': {
          width: 'auto',
          minWidth: '200px', // Imposta una larghezza minima se necessario
        },
      }}
      getOptionLabel={(option: any) => getMemberName(option) as string}
      popupIcon={<IconWrapper iconName={'chevron-right'} className={ `${styles.BreadCrumb__Icon}`} />}
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            placement="bottom-start"
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
              paddingTop: 36,
            }}
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => {

        const memberName = getMemberName( metaDataApiStore?.selectedEntity as any )

        return <TextField
        {...params}
        variant="standard"
        onChange={(ev: any) => (setSearchTerm(ev.target.value)) }
        
        style={{
          width: (((searchTerm?.length || (memberName || t('widgets.dropdowns.members') as string)?.length) * 10) + 10) || 0,
          minWidth: 100,
          maxWidth: 300,

        }}
          
        className={ styles.BreadCrumb__Input }

        label={(memberName || t('widgets.dropdowns.members') as string)}
        placeholder={(memberName|| t('widgets.dropdowns.members') as string)}
      >
      </TextField>
      }
        
      }
      renderOption={(props, option, { selected }) => {

        return <ListItem
          {...props}
          className={`${ option?.memberId === metaDataApiStore?.selectedEntityId ? styles.Autocomplete__List__Item__Active : styles.Autocomplete__List__Item}`}
          value={option.value}
        >
          <span
            style={{ paddingLeft: 24, cursor: 'pointer' }}
            onClick={(ev: any) =>
              navigateToMember(option, setMetaDataApiStore, setModulesStore)
            }
          >
            {getMemberName(option)}
          </span>
        </ListItem>
      }
      }
    />
  );
}

export function SitesDropDown() {
  const { t } = useTranslation();
  const { setModulesStore } = useContext(ModulesContext);
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  const [searchTerm, setSearchTerm] = useState<string>('');

  
  const membersList = ([
    ...((metaDataApiStore?.sortedEntities as any)?.communities || []).reduce((prev: any, next: any) => ([...prev, ...(next?.members || [])]), []),
    ...((metaDataApiStore as any)?.unassignedMembers || [])
  ])
    ?.filter((member: any) => member?.sites?.some((site: any) => site?.siteId === metaDataApiStore?.selectedEntityId));
  
  const sitesList = membersList?.map((member: any) => member?.sites).flat();


  return (
    <Autocomplete
      disablePortal
      id="autocomplete-sites"
      clearOnBlur={false}
      className={`${styles.BreadCrumb}`}
      options={sitesList || []}
      // groupBy={(option: any) => option.timeSpan}
      sx={{
        width: 'auto',
        '& .MuiAutocomplete-input': {
          width: 'auto',
          minWidth: '300px', // Imposta una larghezza minima se necessario
        },
      }}
      disabled={sitesList.length <= 1}
      getOptionLabel={(option: any) => option.pod}
      popupIcon={
        sitesList.length <= 1
          ? null
          : <IconWrapper
          iconName={'chevron-right'}
          className={`${styles.BreadCrumb__Icon}`}
        />
      }
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            placement="bottom-start"
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
              paddingTop: 36,
            }}
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          onChange={(ev: any) => setSearchTerm(ev.target.value)}
          style={{
            width: (((searchTerm?.length || ((metaDataApiStore?.selectedEntity as any)?.name || (metaDataApiStore?.selectedEntity as any)?.siteName)?.length) * 10) + 10) || 0,
            minWidth: 150,
            maxWidth: 300,
          }}

          className={ styles.BreadCrumb__Input }
          label={
            (metaDataApiStore?.selectedEntity as any)?.pod ||
            (t('widgets.dropdown.sites') as string)
          }
          placeholder={
            (metaDataApiStore?.selectedEntity as any)?.pod ||
            (t('widgets.dropdown.sites') as string)
          }
        ></TextField>
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem
          {...props}
          className={`${ option?.siteId === metaDataApiStore?.selectedEntityId ? styles.Autocomplete__List__Item__Active : styles.Autocomplete__List__Item}`}
          value={option.value}
        >
          <span
            style={{ paddingLeft: 24, cursor: 'pointer' }}
            onClick={(ev: any) =>
              navigateToSite(
                option,
                setMetaDataApiStore,
                setModulesStore,
                metaDataApiStore?.entityType
              )
            }
          >
            {option?.name}
          </span>
        </ListItem>
      )}
    />
  );
}