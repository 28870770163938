// Import necessary modules and types
import axios, { AxiosResponse } from 'axios';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';

// Declare global cordova variable for TypeScript
declare global {
  interface Window {
    cordova: any;
  }
}

// Access environment variables
const ENV = (import.meta as any).env;

// Determine if the app is running on a native platform (iOS or Android)
const isNative = Capacitor.isNativePlatform();

// Create an Axios instance for the web platform with the base URL
const api = axios.create({
  baseURL: ENV.VITE_APP_BASE_URL,
});

// Request interceptor for setting headers in Axios
api.interceptors.request.use(
  (config) => {
    // If an idToken is provided, set the Authorization header
    if (config.headers.idToken) {
      config.headers.Authorization = `Bearer ${config.headers.idToken}`;
      delete config.headers.idToken; // Remove idToken after setting Authorization
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for centralized error handling in Axios
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Enhanced error handling
    if (error.response) {
      // The request was made, and the server responded with a status code outside of 2xx
      // console.error('Response Data:', error.response.data);
      // console.error('Status Code:', error.response.status);
      // console.error('Headers:', error.response.headers);
    } else if (error.request) {
      // The request was made, but no response was received
      // console.error('No Response:', error.request);
    } else {
      // An error occurred in setting up the request
      // console.error('Error', error.message);
    }
    return Promise.reject(error);
  }
);

// Define the interface for request options
interface RequestOptions {
  url: string;
  data?: any;
  params?: any;
  idToken?: string;
  additionalHeaders?: any;
  mock?: boolean;
}

// Cache for recent requests to prevent duplicates
const requestCache: Record<string, { timestamp: number; data?: any }> = {};
const CACHE_EXPIRY = 5000; // Cache expiry time in milliseconds (1 second)

// Utility function to create a unique cache key based on URL and data
const getCacheKey = (url: string, data?: any): string => {
  return `${url}:${JSON.stringify(data)}`;
};

// Function to check if a request is a duplicate within the cache expiry time
const isDuplicateRequest = (url: string, data?: any): boolean => {
  const cacheKey = getCacheKey(url, data);
  const cachedRequest = requestCache[cacheKey];

  if (cachedRequest) {
    const currentTime = Date.now();
    if (currentTime - cachedRequest.timestamp < CACHE_EXPIRY) {
      return true; // Duplicate request detected within the time window
    }
  }
  return false;
};

// Function to store a request in the cache
const cacheRequest = (url: string, data?: any): void => {
  const cacheKey = getCacheKey(url, data);
  requestCache[cacheKey] = { timestamp: Date.now(), data };
};

// The main httpService object containing methods for GET, POST, PUT, and DELETE
export const httpService = {
  /**
   * GET request method
   * @param {RequestOptions} options - The request options
   * @returns {Promise<any>} - The response from the server
   */
  get: async ({ url, idToken, params, mock }: RequestOptions): Promise<any> => {
    // Check for duplicate requests
    if (isDuplicateRequest(url, params)) {
      // console.warn(`Duplicate GET request to ${url} prevented.`);
      return {
        status: 999,
        statusText: 'OK',
      } as any;
    }

    // Construct the full URL for native platforms
    const fullUrl = url;

    const getMock = () => {
      if (mock) return 'true';
      else return ENV?.VITE_APP_MOCK === 'true' ? 'true' : 'false';
    };

    // Set up headers
    const headers = {
      mock: getMock(),
      'X-Tenant-ID': ENV.VITE_APP_TENANT,
      'X-API-Key': ENV.VITE_APP_TENANT_API_KEY,
      Authorization: `Bearer ${idToken}`,
    };

    try {
      let response;

      if (
        isNative &&
        window.cordova &&
        window.cordova.plugin &&
        window.cordova.plugin.http
      ) {
        // Native platform using cordova-plugin-advanced-http with SSL pinning
        response = await new Promise((resolve, reject) => {
          window.cordova.plugin.http.get(
            fullUrl,
            params || {},
            headers,
            (res: any) => {
              // Success callback
              if (res.data && typeof res.data === 'string') {
                try {
                  res.data = JSON.parse(res.data);
                } catch (e) {
                  // console.error('Error parsing response data:', e);
                }
              }
              resolve(res);
            },
            (err: any) => {
              // Error callback
              reject(err);
            }
          );
        });
      } else {
        // Web platform using Axios
        response = await api.get(url, {
          headers,
          params,
        });
      }

      // Cache the request after a successful response
      cacheRequest(url, params);

      // Log the successful request to Sentry
      Sentry.captureEvent({
        message: `GET request to ${url} was successful`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'info',
        extra: { url, params },
      });

      return response;
    } catch (error) {
      // Log the error to Sentry
      Sentry.captureEvent({
        message: `GET request to ${url} resulted in an error`,
        level: 'warning',
        extra: { url, params, error },
      });
      throw error;
    }
  },

  /**
   * POST request method
   * @param {RequestOptions} options - The request options
   * @returns {Promise<any>} - The response from the server
   */
  post: async ({
    url,
    data,
    idToken,
    additionalHeaders,
  }: RequestOptions): Promise<any> => {
    // Check for duplicate requests
    if (isDuplicateRequest(url, data)) {
      // console.warn(`Duplicate POST request to ${url} prevented.`);
      return {
        status: 999,
        statusText: 'OK',
      } as any;
    }

    // Construct the full URL for native platforms
    const fullUrl = url;

    // Set up headers
    const headers = {
      'Content-Type': 'application/json',
      mock: ENV?.VITE_APP_MOCK === 'true' ? 'true' : 'false',
      'X-Tenant-ID': ENV.VITE_APP_TENANT,
      'X-API-Key': ENV.VITE_APP_TENANT_API_KEY,
      Authorization: `Bearer ${idToken}`,
      ...additionalHeaders,
    };

    try {
      let response;

      if (
        isNative &&
        window.cordova &&
        window.cordova.plugin &&
        window.cordova.plugin.http
      ) {
        // Native platform using cordova-plugin-advanced-http with SSL pinning
        response = await new Promise((resolve, reject) => {
          window.cordova.plugin.http.post(
            fullUrl,
            data || {},
            headers,
            (res: any) => {
              // Success callback
              if (res.data && typeof res.data === 'string') {
                try {
                  res.data = JSON.parse(res.data);
                } catch (e) {
                  // console.error('Error parsing response data:', e);
                }
              }
              resolve(res);
            },
            (err: any) => {
              // Error callback
              reject(err);
            }
          );
        });
      } else {
        // Web platform using Axios
        response = await api.post(url, data, {
          headers,
        });
      }

      // Cache the request after a successful response
      cacheRequest(url, data);

      // Log the successful request to Sentry
      Sentry.captureEvent({
        message: `POST request to ${url} was successful`,
        level: 'info',
        extra: { url, data },
      });

      return response;
    } catch (error) {
      // Log the error to Sentry
      Sentry.captureEvent({
        message: `POST request to ${url} resulted in an error`,
        level: 'warning',
        extra: { url, data, error },
      });
      throw error;
    }
  },

  /**
   * PUT request method
   * @param {RequestOptions} options - The request options
   * @returns {Promise<any>} - The response from the server
   */
  put: async ({ url, data, idToken }: RequestOptions): Promise<any> => {
    // Check for duplicate requests
    if (isDuplicateRequest(url, data)) {
      // console.warn(`Duplicate PUT request to ${url} prevented.`);
      return {
        status: 999,
        statusText: 'OK',
      } as any;
    }

    // Construct the full URL for native platforms
    const fullUrl = url;

    // Set up headers
    const headers = {
      'Content-Type': 'application/json',
      mock: ENV?.VITE_APP_MOCK === 'true' ? 'true' : 'false',
      'X-Tenant-ID': ENV.VITE_APP_TENANT,
      'X-API-Key': ENV.VITE_APP_TENANT_API_KEY,
      Authorization: `Bearer ${idToken}`,
    };

    try {
      let response;

      if (
        isNative &&
        window.cordova &&
        window.cordova.plugin &&
        window.cordova.plugin.http
      ) {
        // Native platform using cordova-plugin-advanced-http with SSL pinning
        response = await new Promise((resolve, reject) => {
          window.cordova.plugin.http.put(
            fullUrl,
            data || {},
            headers,
            (res: any) => {
              // Success callback
              if (res.data && typeof res.data === 'string') {
                try {
                  res.data = JSON.parse(res.data);
                } catch (e) {
                  // console.error('Error parsing response data:', e);
                }
              }
              resolve(res);
            },
            (err: any) => {
              // Error callback
              reject(err);
            }
          );
        });
      } else {
        // Web platform using Axios
        response = await api.put(url, data, {
          headers,
        });
      }

      // Cache the request after a successful response
      cacheRequest(url, data);

      // Log the successful request to Sentry
      Sentry.captureEvent({
        message: `PUT request to ${url} was successful`,
        level: 'info',
        extra: { url, data },
      });

      return response;
    } catch (error) {
      // Log the error to Sentry
      Sentry.captureEvent({
        message: `PUT request to ${url} resulted in an error`,
        level: 'warning',
        extra: { url, data, error },
      });
      throw error;
    }
  },

  /**
   * DELETE request method
   * @param {RequestOptions} options - The request options
   * @returns {Promise<any>} - The response from the server
   */
  delete: async ({ url, idToken }: RequestOptions): Promise<any> => {
    // Check for duplicate requests
    if (isDuplicateRequest(url)) {
      // console.warn(`Duplicate DELETE request to ${url} prevented.`);
      return {
        status: 999,
        statusText: 'OK',
      } as any;
    }

    // Construct the full URL for native platforms
    const fullUrl = url;

    // Set up headers
    const headers = {
      mock: ENV?.VITE_APP_MOCK === 'true' ? 'true' : 'false',
      'X-Tenant-ID': ENV.VITE_APP_TENANT,
      'X-API-Key': ENV.VITE_APP_TENANT_API_KEY,
      Authorization: `Bearer ${idToken}`,
    };

    try {
      let response;

      if (
        isNative &&
        window.cordova &&
        window.cordova.plugin &&
        window.cordova.plugin.http
      ) {
        // Native platform using cordova-plugin-advanced-http with SSL pinning
        response = await new Promise((resolve, reject) => {
          window.cordova.plugin.http.delete(
            fullUrl,
            {},
            headers,
            (res: any) => {
              // Success callback
              if (res.data && typeof res.data === 'string') {
                try {
                  res.data = JSON.parse(res.data);
                } catch (e) {
                  // console.error('Error parsing response data:', e);
                }
              }
              resolve(res);
            },
            (err: any) => {
              // Error callback
              reject(err);
            }
          );
        });
      } else {
        // Web platform using Axios
        response = await api.delete(url, {
          headers,
        });
      }

      // Cache the request after a successful response
      cacheRequest(url);

      // Log the successful request to Sentry
      Sentry.captureEvent({
        message: `DELETE request to ${url} was successful`,
        level: 'info',
        extra: { url },
      });

      return response;
    } catch (error) {
      // Log the error to Sentry
      Sentry.captureEvent({
        message: `DELETE request to ${url} resulted in an error`,
        level: 'warning',
        extra: { url, error },
      });
      throw error;
    }
  },
};

export default httpService;
