import { ThemeContext } from '@flexo/providers';
import {  useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

export default function HorizontalChart(props: any) {
  const ref: any = useRef(null);
  const { t } = useTranslation();
  const { ThemeStore } = useContext(ThemeContext);
  const [data, setData] = useState<any>(null);
  const [hoveredBarKey, setHoveredBarKey] = useState<any>(null);

  

  // Funzione per il tooltip personalizzato
  const CustomTooltip: any = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const activePayload = payload.find(
        (item) => item.dataKey === hoveredBarKey
      );
      const unit =
        props?.data?.kpi?.find((datum: any) => datum.name === hoveredBarKey)
          ?.unit || '';

      if (activePayload) {
        return (
          <div
            style={{
              backgroundColor: 'var( --hive-greyscale-100 )',
              padding: '10px',
              borderRadius: '8px',
              fontFamily: 'Flexo',
              fontSize: 14,
            }}
          >
            <p
              style={{
                margin: 0,
                borderBottom: `solid 4px ${activePayload.fill}`,
              }}
            >
              <strong className="font-flexo-semibold">
                {t(
                  `widgets.horizontalChart.${props?.data?.context}.${activePayload.dataKey}`
                )}
              </strong>{' '}
              {props?.data?.context === 'economics'
                ? `${unit} ${activePayload.value} `
                : `${activePayload.value} ${unit}`}
            </p>
          </div>
        );
      }
    }

    return null;
  };

  const CustomBarShape = (_props) => {
    const { x, y, width, height, fill, keyName, index, array } = _props;


    if (!props?.data?.combinedKeys) {
      return <g></g>;
    }

    const isActive = props.data.activeKeys.includes(keyName);

    const sortedActiveKeys = props?.data?.activeKeys.sort((a, b) => {
      return props?.data?.graphKeys.indexOf(a) - props?.data?.graphKeys.indexOf(b);
    });

    const firstCommonKey = sortedActiveKeys.find(key => props?.data?.graphKeys.includes(key));
    const isFirstCommonKey = firstCommonKey === keyName;

    const keyIndex = props?.data?.graphKeys.indexOf(keyName);
    
    function areItemsBeforeNull(index) {
      return props?.data?.graphKeys.slice(0, index).every(item => ((data[0][ item ] === null || data[0][ item ] === undefined)) );
    }

    const indexBeforeAreNull = areItemsBeforeNull(keyIndex);

    const lastCommonKey = [...sortedActiveKeys].reverse().find(key => props?.data?.graphKeys.includes(key));
    const isLastCommonKey = lastCommonKey === keyName;

    return (
      <g>
        {/* Disegna la barra classica */}
        <rect
          x={index === array.length - 1 ? x - 3 : x}
          y={y}
          width={width}
          height={height}
          fill={isActive ? fill : 'transparent'} // Rende invisibile la barra se non è attiva
          style={
            isFirstCommonKey && isLastCommonKey
              ? { clipPath: 'inset(0% 3px 0% 3px round 10px)' }
              : isFirstCommonKey
                ? { clipPath: 'inset(0px 3px 0px 0px)' }
                : isLastCommonKey
                  ? { clipPath: 'inset(0px 0px 0px 3px)' }
                  : indexBeforeAreNull
                    ? { clipPath: 'inset(0px 3px 0px 0px)' }
                    : {}
          }
          rx={isFirstCommonKey || isLastCommonKey || indexBeforeAreNull ? 4 : 0}
        />

        {/* Aggiungi un bordo bianco a destra se keyName è uguale a specificKey */}
        
        {( !isLastCommonKey && !props?.data?.combinedKeys?.includes(keyName) ) && (
          <rect
            x={x + width - 3} // Posiziona la linea sul lato destro
            y={y}
            width={3} // Larghezza del bordo destro
            height={height}
            fill="#FFFFFF" // Colore del bordo
          />
        )}
      </g>
    );
  };



  useEffect(() => {
    if (props?.data?.kpi && props?.data?.graphKeys) {
      const _data = props.data.kpi
        .filter((datum: any) =>  (props.data.activeKeys.includes(datum.name) && datum?.value !== null ) )
        .reduce(
          (prev, next) => ({
            ...prev,
            [next.name]: next.value,
          }),
          {
            name: t(props.data.rankingKpiName),
          }
      );

      setData([_data]);
    }
  }, [props.data.kpi, props.data.graphKeys]);

  useEffect(() => {
    if (props?.data?.kpi && props?.data?.graphKeys) {
      const _data = props.data.kpi
        .filter((datum: any) => (props.data.activeKeys.includes(datum.name) && datum?.value !== null )) 
        .reduce(
          (prev, next) => ({
            ...prev,
            [next.name]: next.value,
          }),
          {
            name: t(props.data.rankingKpiName), 
          }
      );

      setData([_data]);
    }
  }, [props.data.kpi, props.data.activeKeys]);

  return data ? (
    <ResponsiveContainer width={'100%'} height={10} ref={ref}>
      <BarChart
        layout="vertical" 
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis type="number" hide={true} axisLine={false} />
        <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} />
        <Tooltip isAnimationActive={ false } content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
        {props?.data?.graphKeys.map((key: string, index: number, array: any) => (
          <Bar
            isAnimationActive={ false }
            key={key}
            id={key}
            dataKey={key}
            stackId={props.data.rankingKpiName}
            onMouseEnter={() => setHoveredBarKey(key)}
            onMouseLeave={() => setHoveredBarKey(null)}
            fill={`${ThemeStore?.colors[props?.data?.colors[index].color]}${props.data?.colors[index].colorShade}`}
            shape={(barProps) => (
              <CustomBarShape
                {...barProps}
                keyName={barProps.dataKey}
                index={index}
                array={array}
              />
            )}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  ) : null;
}
