import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { formatDate } from '../helpers';
import { CalendarContext, ThemeContext } from '@flexo/providers';
import { useTranslation } from 'react-i18next';
import styles from './stackbar-graph.module.scss';

const ENV = (import.meta as any).env;

const CustomBarShape = (props) => {
  const { fill, x, y, width, height, bottom, fullRadius } = props;
  // Return nothing if width or height is zero or negative
  if (width <= 0 || height <= 0) {
    return null;
  }

  if (fullRadius) {
    // All four corners rounded
    return (
      <g>
        <path
          d={`
            M ${x + 5}, ${y}
            h ${width - 10}
            a 5 5 0 0 1 5 5
            v ${height - 10}
            a 5 5 0 0 1 -5 5
            h ${-(width - 10)}
            a 5 5 0 0 1 -5 -5
            v ${-(height - 10)}
            a 5 5 0 0 1 5 -5
            z
          `}
          fill={fill}
        />
        <line
          x1={x}
          y1={y}
          x2={x}
          y2={y + height}
          stroke="white"
          strokeWidth={3}
        />
        <line
          x1={x + width}
          y1={y}
          x2={x + width}
          y2={y + height}
          stroke="white"
          strokeWidth={3}
        />
      </g>
    );
  } else if (bottom) {
    // Bottom corners rounded
    return (
      <g>
        <path
          d={`
            M ${x}, ${y}
            h ${width}
            v ${height - 5}
            a 5 5 0 0 1 -5 5
            h ${-(width - 10)}
            a 5 5 0 0 1 -5 -5
            v ${-(height - 5)}
            z
          `}
          fill={fill}
        />
        <line
          x1={x}
          y1={y}
          x2={x}
          y2={y + height}
          stroke="white"
          strokeWidth={3}
        />
        <line
          x1={x + width}
          y1={y}
          x2={x + width}
          y2={y + height}
          stroke="white"
          strokeWidth={3}
        />
      </g>
    );
  } else {
    // Top corners rounded
    return (
      <g>
        <path
          d={`
            M ${x}, ${y + 5}
            a 5 5 0 0 1 5 -5
            h ${width - 10}
            a 5 5 0 0 1 5 5
            v ${height - 5}
            h ${-width}
            z
          `}
          fill={fill}
        />
        <line
          x1={x}
          y1={y}
          x2={x}
          y2={y + height}
          stroke="white"
          strokeWidth={3}
        />
        <line
          x1={x + width}
          y1={y}
          x2={x + width}
          y2={y + height}
          stroke="white"
          strokeWidth={3}
        />
      </g>
    );
  }
};

export const Stackbar = ({
  _data,
  keys,
  combinedKeys,
  colors,
  title,
  unit,
  chartValues,
  subtype,
}) => {
  const { calendarStore } = useContext(CalendarContext);
  const { ThemeStore } = useContext(ThemeContext);
  const [selectedData, setSelectedData] = useState(null);
  const [visibleKeys, setVisibleKeys] = useState({});

  useEffect(() => {
    if (chartValues && chartValues.length > 0) {
      setVisibleKeys(
        chartValues.reduce((acc, key) => {
          acc[key.key] = true;
          return acc;
        }, {})
      );
    }
  }, [chartValues]);

  const [activeIndex, setActiveIndex] = useState(null); // Track the active bar

  const { t } = useTranslation();

  const CustomTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y}
        dy={10}
        textAnchor="middle"
        className="detail"
        fontSize={12}
        fill="#838B93"
      >
        {props?.className?.includes('yAxis')
          ? payload.value
          : formatDate(payload.value, calendarStore.currentlySelectedDateType)}
      </text>
    );
  };

  const preprocessedData = useMemo(() => {

    const result = _data?.map((entry) => {
      // Check if combinedKeys exists and if the required keys exist in the entry
      const key1Value = entry[combinedKeys?.[1]];
      const key0Value = entry[combinedKeys?.[0]];

      return {
        ...entry,
        ...(combinedKeys && key1Value !== undefined && key0Value !== undefined
          ? {
              [combinedKeys[1]]: key1Value - key0Value,
            }
          : {}), // Add an empty object if values are missing
      };
    });

    return result;
  }, [_data, combinedKeys]);

  const handleMouseMove = (state) => {
    const { activeTooltipIndex } = state || {};

    if (activeTooltipIndex !== undefined) {
      setActiveIndex(activeTooltipIndex); // Set active index based on mouse or simulated touch interaction

      const selected = (preprocessedData || []).find((el) => {
        return el.date === state.activeLabel;
      });
      setSelectedData(selected);
    }
  };

  // const handleTouchMove = (e, state) => {
  //   console.log(e, 'why');
  //   // if (!e.touches || e.touches.length === 0) {
  //   //   return; // Exit if there are no touches
  //   // }

  //   const overedElement = (document as any).elementFromPoint(
  //     e.changedTouches[0].clientX,
  //     e.changedTouches[0].clientY
  //   );

  //   console.log(overedElement, 'overedElement');

  //   // Simulate the mouse move event by using touch coordinates
  //   const touch = e.touches[0];
  //   const simulatedEvent = new MouseEvent('mousemove', {
  //     clientX: touch.clientX,
  //     clientY: touch.clientY,
  //     bubbles: true,
  //   });

  //   // Dispatch the event as if it were a mouse move
  //   e.target.dispatchEvent(simulatedEvent);
  // };

  const handleMouseLeave = () => {
    setActiveIndex(null); // Reset when the mouse leaves the chart area
    setSelectedData(null);
  };
  function getGreyScaledColor(color: string) {
    let _color = color;

    if (color?.includes('#')) {
      const red = parseInt(color.substr(1, 2), 16);
      const green = parseInt(color.substr(3, 2), 16);
      const blue = parseInt(color.substr(5, 2), 16);
      // Calculate the grayscale value
      const grayscale = Math.round(0.299 * red + 0.587 * green + 0.114 * blue);
      // Convert the grayscale value back to a hex string
      const grayscaleHex = grayscale.toString(16).padStart(2, '0');
      // Return the grayscale hex string with '#' prefix
      // if (color === '#A6AFB0' || color === '#4F6367') {
      //   _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex + '50';
      // } else
      _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex + '33';
    }
    return _color;
  }
  const getFillColor = (index, defaultColor) => {
    if (!activeIndex) return defaultColor;
    return index === activeIndex
      ? defaultColor
      : getGreyScaledColor(defaultColor); // Highlight the active bar, grey out others
  };

  const chartRef: any = useRef(null);

  const getInterval = () => {
    switch (calendarStore.currentlySelectedDateType) {
      case 'day':
      case 'week':
      case 'month':
        return 6;
      case 'year':
        return 2;
      default:
        return 0;
    }
  };

  const getGraphButton = (key) => {
    return `widgets.${subtype}.${key}`;
  };

  const toggleKeyVisibility = (key) => {
    setVisibleKeys({
      ...visibleKeys,
      [key.key]: !visibleKeys[key.key],
    });
  };
  const getStyling = (key, color) => {
    const baseStyle = {
      borderRadius: '1px',
      color: '#505558',
      padding: '0em',
      paddingLeft: combinedKeys?.[1]?.[key] === key.key ? '.5em' : '0',
    };

    if (visibleKeys[key.key] === false) {
      return {
        ...baseStyle,
        borderBottom: `4px solid #F7F7F7`,
        marginRight:
          combinedKeys?.[0]?.[key] === key.key ? '-10px !important' : '0',
      };
    } else {
      return {
        ...baseStyle,
        borderBottom: `4px solid ${ThemeStore.colors[color]}`,
        borderColor: ThemeStore.colors[color],
        marginRight:
          combinedKeys?.[0]?.[key] === key.key ? '-10px !important' : '0',
      };
    }
  };

  // Function to determine which bar the touch is on based on the x-coordinate
  const getBarIndexFromTouch = (touchX) => {
    if (!chartRef.current) return null;

    const chartRect = chartRef.current?.getBoundingClientRect();
    const chartWidth = chartRect.width;
    const barWidth = chartWidth / preprocessedData.length; // Calculate the width of each bar
    const index = Math.floor((touchX - chartRect.left) / barWidth); // Calculate which bar is being touched

    // Ensure the index is within the bounds of the data array
    return Math.min(Math.max(0, index), preprocessedData.length - 1);
  };

  const handleTouchMove = (event) => {
    const touch = event.touches[0];
    const index = getBarIndexFromTouch(touch.clientX);
    setActiveIndex(index as any);

    const selected = preprocessedData[index as any]; // Find the selected data for the active bar
    setSelectedData(selected);
  };

  const handleTouchEnd = () => {
    setActiveIndex(null); // Reset active index when touch ends
  };

  const getVisibleKeysLength = (visibleKeys) => {
    return Object.values(visibleKeys).filter((isVisible) => isVisible).length;
  };

  const getRadiusBottomOrTop = (index, visibleKeysLength) => {
    if (visibleKeysLength >= chartValues?.length - 1) {
      if (
        visibleKeys[combinedKeys[0].key] &&
        visibleKeys[combinedKeys[1].key] &&
        index !== 0
      )
        return false;
    } else if (index === 0) return true;

    return false;
  };

  const getFullRadius = (visibleKeysLength) => {
    return visibleKeysLength >= 2 && visibleKeysLength < chartValues.length;
  };

  console.log( 'preprocessedData', preprocessedData )

  return (
    <div
      className={ENV.VITE_APP_NAME === 'admin' ? styles.StackBar__Wrapper : ''}
      ref={chartRef}
    >
      <div className={styles.Stackbar__Header}>
        {title && (
          <div className={styles.GraphHeader__TitleContainer}>
            {selectedData && (
              <div
                className="heading4"
                style={{ color: '#001117', marginRight: '.5em' }}
              >
                {formatDate(
                  selectedData ? (selectedData as any)?.date : null,
                  calendarStore.currentlySelectedDateType
                )}
              </div>
            )}
            <div
              className={`${styles.GraphHeader__Title} ${
                selectedData ? 'heading4L' : 'heading4'
              }`}
              style={{ color: '#001117' }}
            >
              {t(title)}{' '}
            </div>
          </div>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {chartValues?.map((key, index) => {
            const selectedDate = selectedData
              ? (selectedData as any).date
              : null;

            let activeValue = null;

            try {
              activeValue = selectedData
                ? _data.find((obj) => obj.date === selectedDate)?.[
                    getGraphButton(key.key)
                  ]
                : null;
            } catch (error) {
              activeValue = null;
            }

            // If the key is combined, render it with a different style
            if (key.combined) {
              return (
                <button
                  key={key.key}
                  className={`detail ${styles.Stackbar__Key__Combined}`}
                  onClick={() => toggleKeyVisibility(key)}
                  style={{ ...getStyling(key, key.color) }} // Adjust the style as needed
                >
                  {`${t(getGraphButton(key.key))} `}
                  {visibleKeys[key.key] && activeValue ? (
                    <strong>
                      | {activeValue} {unit}
                    </strong>
                  ) : null}
                </button>
              );
            }

            // Default rendering for non-combined keys
            return (
              <button
                key={key.key}
                className={`detail ${styles.Stackbar__Key}`}
                onClick={() => toggleKeyVisibility(key)}
                style={{ ...getStyling(key, key.color), marginRight: '10px' }}
              >
                {`${t(getGraphButton(key.key))} `}
                {visibleKeys[key.key] && activeValue ? (
                  <strong>
                    | {activeValue} {unit}
                  </strong>
                ) : null}
              </button>
            );
          })}
        </div>
      </div>
      <div
        style={{
          width: '100%', // Ensure the div takes up full width
          height: '280px', // Give the div a fixed height or adjust as needed
          paddingLeft: ENV.VITE_APP_NAME === 'admin' ? 0 : '16px',
          marginTop: ENV.VITE_APP_NAME === 'admin' ? 0 : '-16px',
        }}
        // onTouchStart={handleTouchMove}
        onTouchMove={handleTouchMove} // Attach the touch handler
        onTouchEnd={handleTouchEnd} // Reset when touch ends
        // onTouchMove={handleTouchMove} // Attach the touch handler to the outer div
        // onMouseLeave={() => setActiveIndex(null)} // Reset when the mouse leaves
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={preprocessedData}
            margin={{ top: 50, right: 30, left: -35, bottom: 0 }}
            barCategoryGap="1%"
            onMouseMove={handleMouseMove} // Handle mouse interaction
            onMouseLeave={handleMouseLeave} // Reset when mouse leaves
          >
            <XAxis
              dataKey="date"
              tickFormatter={(tick) =>
                formatDate(tick, calendarStore.currentlySelectedDateType)
              }
              tickLine={false}
              interval={getInterval()}
              tick={<CustomTick />}
              fontSize={12}
              axisLine={{ stroke: '#F7F7F7' }}
            />
            <YAxis
              axisLine={{ stroke: '#F7F7F7' }}
              tickLine={false}
              tick={<CustomTick />}
              fontSize={12}
              scale="linear"
              label={{
                value: unit,
                position: 'insideTop', // Adjust to "insideTop" to ensure it aligns inside at the top
                offset: -25, // Negative offset to move it above the axis line
                fill: '#838B93',
                className: 'detailBold',
                fontSize: 14,
                dx: 20, // This will move the label slightly to the right
              }}
              tickMargin={10}
              // padding={{ bottom: 10 }} places 0 in the right place but pushes the bars up
            />

            {chartValues?.map((value, index) => {
              if (!value.combined && visibleKeys[value.key]) {
                return (
                  <Bar
                    key={getGraphButton(value.key)}
                    dataKey={getGraphButton(value.key)}
                    fill={getFillColor(index, ThemeStore?.colors[value.color])}
                    stackId="a"
                    radius={[5, 5, 5, 5]}
                    strokeWidth={3}
                    stroke="white"
                    // id={}
                  >
                    {preprocessedData?.map((entry, i) => (
                      <Cell
                        key={`cell-${i}`}
                        fill={getFillColor(i, ThemeStore?.colors[value.color])}
                        data-index={i} // Add data-index here to each Cell
                      />
                    ))}
                  </Bar>
                );
              }
              return null;
            })}
            {combinedKeys?.length > 0 &&
              combinedKeys.map((key, visibleIndex) => {
                if (visibleKeys && visibleKeys[key.key]) {
                  const visibleKeysLength = getVisibleKeysLength(visibleKeys);

                  return (
                    <Bar
                      key={getGraphButton(key.key)}
                      dataKey={getGraphButton(key.key)}
                      fill={getFillColor(
                        visibleIndex,
                        ThemeStore.colors[key.color]
                      )}
                      shape={
                        <CustomBarShape
                          bottom={getRadiusBottomOrTop(
                            visibleIndex,
                            visibleKeysLength
                          )}
                          fullRadius={getFullRadius(visibleKeysLength)}
                        />
                      }
                      stackId="a"
                      radius={[3, 3, 0, 0]}
                    >
                      {preprocessedData?.map((entry, i) => (
                        <Cell
                          key={`cell-${i}`}
                          fill={getFillColor(i, ThemeStore.colors[key.color])}
                          stroke="white"
                          strokeWidth={3}
                          strokeDasharray="0 2 0 2"
                          data-index={i} // Add data-index here to each Cell
                        />
                      ))}
                    </Bar>
                  );
                }
                return null;
              })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Stackbar;
