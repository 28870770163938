import React, { useContext, useEffect, useRef, useState } from 'react';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import { useTranslation } from 'react-i18next';
import { IRightDrawerTypes, MetaDataApiContext, ModulesContext, RightDrawerContext } from '@flexo/providers';

import styles from './map.module.scss';
import { IconWrapper } from '@flexo/general';
import { navigateToSite } from '../meta-resolvers/site-resolver';
import { timeFormat } from 'd3-time-format';

export function EsriMapComponent(props) {
  const { wrapper = null } = props;
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { setRightDrawerStore } = useContext(RightDrawerContext);
  const formatDate = timeFormat('%d/%m/%Y');
  const { t } = useTranslation();

  // Riferimento al div che contiene la mappa
  const mapDiv = useRef<HTMLDivElement>(null);
  
  const mapRef = useRef<__esri.Map | null>(null);
  const viewRef = useRef<__esri.MapView | null>(null);
  const layerRef = useRef<__esri.GraphicsLayer | null>(null);

  
  const [shownSite, setShownSite] = useState<any>(null);
  const [mapInitialized, setMapInitialized] = useState(false);

  let mapHeight = wrapper === 'drawer' ? '100vh' : 216;
  let radius = 16;

  
  if (!metaDataApiStore?.entities) return <></>;

  let { sites } = (metaDataApiStore?.entities as any) || {};
  let singleSite: any = null;
  const context = props?.context || metaDataApiStore?.entityType || '';

  if (context?.includes('site') && (metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId)) {
    singleSite =
      metaDataApiStore?.selectedEntity ||
      (metaDataApiStore as any)?.entities?.site?.find(
        (s: any) => s.siteId === metaDataApiStore?.selectedEntityId
      ) ||
      null;
    sites = [singleSite];
  }

  if (context?.includes('community') && metaDataApiStore?.selectedEntity) {
    sites = [
      ...((metaDataApiStore as any)?.entities?.site?.filter(
        (site: any) => site.communityId === metaDataApiStore?.selectedEntityId
      ) || []),
    ];
  }

  if (context?.includes('member') && metaDataApiStore?.selectedEntity) {
    sites = (metaDataApiStore?.entities as any)?.site?.filter(
      (site: any) => site.memberId === (metaDataApiStore?.selectedEntity as any)?.memberId
    );
  }

  if (['site-energy', 'site-economics', 'community-energy', 'community-economics', 'member-energy']?.includes(context)) {
    mapHeight = 400;
    radius = 0;

    const sitesTypes = sites?.map((site: any) => site?.siteType?.[0]?.siteType || site?.type || 'default') || [];
    if (
      singleSite?.siteType?.[0]?.siteType === 'consumer' ||
      singleSite?.type === 'consumer' ||
      singleSite?.siteType?.[0]?.siteType === 'producer' ||
      singleSite?.type === 'producer'
    ) {
      radius = 16;
      mapHeight = 216;
    } else if (sitesTypes?.every((el) => el === 'consumer' || el === 'producer') && context === 'member-energy') {
      radius = 16;
    }
  }

  if (wrapper === 'drawer') {
    radius = 0;
    mapHeight = '100vh';
  }

  
  function goToSite(site: any) {
    if (wrapper === 'drawer') {
      setRightDrawerStore({
        type: IRightDrawerTypes.ResetDrawer,
        payload: {} as any,
      });
    }
    setShownSite(null);
    navigateToSite(site, setMetaDataApiStore, setModulesStore, 'site');
  }

  function expandMap() {
    setRightDrawerStore({
      type: IRightDrawerTypes.SetDrawer,
      payload: {
        value: {
          ...props,
          type: 'EXPANDED_MAP',
        } as any,
      },
    });
  }

  function closeDrawer() {
    setRightDrawerStore({
      type: IRightDrawerTypes.ResetDrawer,
      payload: {} as any,
    });
  }

  function showSite(siteData: any) {
    if (shownSite && shownSite.siteId === siteData?.siteId) {
      
      setShownSite(null);
      return;
    }
    setShownSite(siteData);
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (!mapDiv.current) return;
    if (mapRef.current || viewRef.current) return;

    const webmap = new ArcGISMap({
      basemap: 'streets-navigation-vector',
    });
    const graphicsLayer = new GraphicsLayer();
    webmap.add(graphicsLayer);

    // Crea la view
    const view = new MapView({
      container: mapDiv.current,
      map: webmap,
      center: [0, 0],
      zoom: 13,
    });

    view.on('click', (event) => {
      view.hitTest(event).then((response) => {
        if (response.results.length > 0) {
          
          const graphicResult = response.results.find((r: any) => r.graphic?.layer === graphicsLayer);
          if (graphicResult) {

            const siteData = (graphicResult as any).graphic.attributes;
            const geometry = (graphicResult as any).graphic.geometry;

            const screenPoint = view.toScreen(geometry as any);

            showSite({
              ...siteData,
              screenX: screenPoint.x,
              screenY: screenPoint.y
            });

          } else {
            setShownSite(null);
          }
        }
      });
    });

    view.on('mouse-wheel', () => setShownSite(null));

    mapRef.current = webmap;
    viewRef.current = view;
    layerRef.current = graphicsLayer;

    return () => {
      view.destroy();
    };
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    if (!layerRef.current || !viewRef.current || !sites) return;

    layerRef.current.removeAll();

    const simpleMarkerSymbol = {
      type: 'simple-marker',
      color: [0, 158, 98],
      size: 15,
      outline: {
        color: [255, 255, 255], // White
        width: 2,
      },
    };

    
    sites.forEach((site: any) => {
      
      if (site?.siteId && (!site?.siteLatitude || !site?.siteLongitude)) {
        const coords = (metaDataApiStore?.entities as any)?.site?.find((_s: any) => _s.siteId === site.siteId);
        site = {
          ...site,
          ...coords,
        };
      }
      if (!site?.siteId || !site?.siteLongitude || !site?.siteLatitude) {
        return; // skip
      }

      const pointGraphic = new Graphic({
        geometry: {
          type: 'point',
          longitude: site.siteLongitude,
          latitude: site.siteLatitude,
        },
        symbol: simpleMarkerSymbol,
        attributes: site,
      } as any);

      layerRef.current?.add(pointGraphic);
    });

    
    viewRef.current?.when(() => {
      if (layerRef.current && layerRef.current.graphics.length > 0) {
        if (!mapInitialized) {
          viewRef.current?.goTo(layerRef.current.graphics.toArray(), { animate: false });
          setMapInitialized(true); // la prossima volta non lo rifaccio
        }

      }

      

    });
  }, [sites]);
  
  return (
    <div className={styles.Map__Wrapper} style={{ borderRadius: radius }}>
      
      {wrapper !== 'drawer' && shownSite && (
        <div className={styles.Map__Shown__Site} style={{ left: shownSite.screenX, top: shownSite.screenY }}>
          <p
            className={styles.Map__Shown__Site__Pod}
            onClick={() => goToSite(shownSite)}
            onMouseLeave={() => setShownSite(null)}
          >
            {shownSite?.pod}
          </p>
          <p className={styles.Map__Shown__Site__Name}>{shownSite?.siteName}</p>
        </div>
      )}

      <div ref={mapDiv} id="mapViewNode" style={{ width: '100%', height: mapHeight }} />

      
      {wrapper !== 'drawer' && (
        <div className={styles?.Map__Button__Expand} onClick={() => expandMap()}>
          <IconWrapper iconName="expand" />
        </div>
      )}

      
      <div
        className={styles?.Map__Shown__Site__Expanded}
        style={{
          opacity: wrapper === 'drawer' && shownSite ? 1 : 0,
          height: wrapper === 'drawer' && shownSite ? 'auto' : 0,
        }}
      >
        <p
          className={styles?.Map__Shown__Site__Expanded__Pod}
          onClick={() => goToSite(shownSite)}
        >
          <span>{shownSite?.pod}</span>
          {shownSite?.siteName}
        </p>
        <p className={styles?.Map__Shown__Site__Expanded__Member}>
          <span>
            {shownSite?.members?.[0]?.memberFirstName} {shownSite?.members?.[0]?.memberLastName}
          </span>
          {shownSite?.members?.[0]?.memberExternalId || '-'}
        </p>
        <p className={styles?.Map__Shown__Site__Expanded__Address}>
          <span>{shownSite?.members?.[0]?.address}</span>
          {shownSite?.members?.[0]?.city}, {shownSite?.members?.[0]?.postcode}
        </p>
        <p className={styles?.Map__Shown__Site__Expanded__Type}>
          {t(`widgets.map.${shownSite?.siteType?.[0]?.siteType || 'default'}`)}
        </p>
        <p className={styles?.Map__Shown__Site__Expanded__Owned}>
          <span>{t('widgets.map.owned')}:</span>&nbsp;
          {shownSite?.joinDate
            ? formatDate(new Date(shownSite.joinDate))
            : 'Unknown join date'}{' '}
          -
        </p>
      </div>

      
      {wrapper === 'drawer' && (
        <div className={styles?.Map__Button__Close} onClick={() => closeDrawer()}>
          <IconWrapper iconName="cross" />
        </div>
      )}
    </div>
  );
}