// eslint-disable-prototype-builtins

import styles from './information-card.module.scss';
import { useContext, useState } from 'react';
import {
  KpiApiContext,
  ModalContext,
  ModalTypes,
  IRightDrawerTypes,
  RightDrawerContext,
  MetaDataApiContext,
  ModulesContext,
} from '@flexo/providers';

import { useTranslation } from 'react-i18next';
import { IconWrapper } from '@flexo/general';
import { v4 as uuidv4 } from 'uuid';
import { navigateToCommunity } from '../resolvers/community-resolver';
import { navigateToSite } from '../meta-resolvers/site-resolver';
import { navigateToMember } from '../resolvers/member-resolver';
import { get } from 'http';

const ENV = (import.meta as any).env;

export function InformationCard(props: any) {
  const {
    InformationCard__Icon,
    InformationCard__Value,
    InformationCard__Label,
    InformationCard__Label__Disabled,
    InformationCard__Detail,
  } = styles;

  const { t } = useTranslation();
  const { kpiApiStore, setKpiApiStore } = useContext(KpiApiContext);
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { setRightDrawerStore } = useContext(RightDrawerContext);
  const { setModalStore } = useContext(ModalContext);

  const { site, community, member } = kpiApiStore;

  let overviewKpiApiData = kpiApiStore?.site?.['1day']?.[0]?.kpi as any;

  if (metaDataApiStore?.entityType?.includes('community')) {
    overviewKpiApiData = community?.['1day']?.[0]?.kpi as any;
  }

  if (metaDataApiStore?.entityType?.includes('member')) {
    overviewKpiApiData = member?.['1day']?.[0]?.kpi as any;
  }

  const onClickLabel = () => {
    switch (props?.env || '') {
      case 'web':
        if (props?.descriptionCallback === 'communityEnergy') {
          navigateToCommunity(
            metaDataApiStore?.selectedEntityId,
            setMetaDataApiStore,
            setModulesStore,
            'energy'
          );
        } else if (props?.descriptionCallback === 'siteEnergy') {
          navigateToSite(
            metaDataApiStore?.selectedEntityId,
            setMetaDataApiStore,
            setModulesStore,
            'energy'
          );
        } else if (props?.descriptionCallback === 'communityEconomics') {
          navigateToCommunity(
            metaDataApiStore?.selectedEntityId,
            setMetaDataApiStore,
            setModulesStore,
            'economics'
          );
        } else if (props?.descriptionCallback === 'MemberBenefits') {
          navigateToMember(
            metaDataApiStore?.selectedEntity,
            setMetaDataApiStore,
            setModulesStore,
            'economics'
          );
        } else if (props?.descriptionCallback === 'memberEnergy') {
          navigateToMember(
            metaDataApiStore?.selectedEntity,
            setMetaDataApiStore,
            setModulesStore,
            'energy'
          );
        } else if (
          props?.descriptionCallback === 'communityTotalEstimatedBenefits'
        ) {
          setRightDrawerStore({
            type: IRightDrawerTypes.SetDrawer,
            payload: {
              value: {
                ...props,
                type: 'COMMUNITY_TOTAL_ESTIMATED_BENEFITS',
              } as any,
            },
          });
        } else if (
          props?.descriptionCallback === 'memberTotalEstimatedBenefits'
        ) {
          setRightDrawerStore({
            type: IRightDrawerTypes.SetDrawer,
            payload: {
              value: {
                ...props,
                type: 'MEMBER_TOTAL_ESTIMATED_BENEFITS',
              } as any,
            },
          });
        } else if (
          props?.descriptionCallback === 'SiteTotalEstimatedBenefits'
        ) {
          setRightDrawerStore({
            type: IRightDrawerTypes.SetDrawer,
            payload: {
              value: {
                ...props,
                type: 'SITE_TOTAL_ESTIMATED_BENEFITS',
              } as any,
            },
          });
        } else {
          setRightDrawerStore({
            type: IRightDrawerTypes.SetDrawer,
            payload: {
              value: {
                ...props,
              } as any,
            },
          });
        }

        break;
      default:
        if (!props.details.title) return;
        setModalStore({
          type: ModalTypes.OpenModal,
          payload: {
            value: {
              type: 'CARD',
              visible: true,
              show: true,
              props: { ...props },
              content: 'CARD',
            } as any,
          },
        });
        break;
    }
  };

  const getCardValue = () => {
    let cardValue = overviewKpiApiData?.find(
      (el) => el.name === props['totalValue']
    );

    if (props['totalValue'] === 'sites_count') {
      cardValue = {
        value: metaDataApiStore?.sortedEntities?.communities[0]?.members.reduce(
          (prev, next) => prev + (next?.sites?.length || 0),
          0
        ),
        unit: '',
      };
    }

    return (
      <div
        key={uuidv4()}
        className={`${
          ENV.VITE_APP_IS_WEB === 'true' &&
          styles.InformationCard__Web__FeaturedData__Wrapper
        }`}
      >
        <span
          key={uuidv4()}
          className={`featuredData ${
            ENV.VITE_APP_IS_WEB === 'true' &&
            styles.InformationCard__Web__FeaturedData
          }`}
          style={{ fontWeight: 200 }}
        >
          {!cardValue?.value ? '-.-' : cardValue?.value}
          {/* {/[.,]/.test(overviewKpiApiData[props['totalValue']])
            ? ''
            : ',00'}{' '} */}
        </span>
        <span key={uuidv4()} className="heading3L" style={{ fontWeight: 200 }}>
          {' ' + (cardValue?.unit || cardValue?.unit_type || '')}
        </span>
      </div>
    );
  };

  const getCardApiValue = (key) => {
    const cardValue = overviewKpiApiData?.find((el) => el.name === key);

    if (
      cardValue === null ||
      cardValue === undefined ||
      Number.isNaN(cardValue?.value)
    ) {
      return { value: ' -.- ', unit: '' }; // Ensure value and unit are always present
    } else {
      return cardValue;
    }
  };

  function getSiteRanking() {
    const site =
      (metaDataApiStore?.entities as any)?.site?.find(
        (_site: any) =>
          _site.siteId ===
          kpiApiStore?.communityRanking?.economicsRanking?.[0]?.site?.[0]
            ?.siteId
      ) || null;
    return site?.siteName || '';
  }

  const getCardDetail = () => {
    // console.log('props.descriptionType', props.descriptionType);
    const cardValue = getCardApiValue(props['descriptionValue']);

    switch (props.descriptionType) {
      case 'totalSitesCard':
        if (
          !(metaDataApiStore as any)?.sortedEntities ||
          (metaDataApiStore as any)?.entities?.length === 0
        ) {
          return <></>;
        }

        // eslint-disable-next-line
        let community = (
          metaDataApiStore as any
        )?.sortedEntities?.communities.find(
          (community) =>
            community.id === metaDataApiStore?.selectedEntityId ||
            community.communityId === metaDataApiStore?.selectedEntityId
        );

        if (!community) {
          community = (metaDataApiStore as any)?.sortedEntities?.communities[0];
        }

        // eslint-disable-next-line
        const siteTypeCounts = {
          consumer: 0,
          producer: 0,
          prosumer: 0,
          prosumage: 0,
        };

        community.members.forEach((member) => {
          member.sites.forEach((site) => {
            if (Array.isArray(site.siteType)) {
              site.siteType.forEach((type) => {
                if (
                  type.siteType &&
                  // eslint-disable-next-line
                  (siteTypeCounts as any).hasOwnProperty(type.siteType)
                ) {
                  siteTypeCounts[type.siteType]++;
                }
              });
            } else if (typeof site.siteType === 'string') {
              // eslint-disable-next-line
              if (siteTypeCounts.hasOwnProperty(site.siteType)) {
                siteTypeCounts[site.siteType]++;
              }
            }
          });
        });

        for (const key in siteTypeCounts) {
          if (siteTypeCounts[key] === 0) {
            siteTypeCounts[key] = null;
          }
        }

        // eslint-disable-next-line
        const keys = props.description.map((el) =>
          el.key.split('.').slice(-1).pop()
        );

        return (
          <>
            {keys
              .filter((_el: any) => siteTypeCounts[_el] !== null)
              .map((siteType: string, _i: number, array: any) => {
                const label = props.description.find((desc: any) =>
                  desc.key.includes(siteType)
                );

                return (
                  <span key={uuidv4()} className="paragraph">
                    {t(label.key)} {siteTypeCounts[siteType]}{' '}
                    {_i < array.length - 1 ? '|' : ''}
                  </span>
                );
              })}
          </>
        );
      case 'label+value|label+value':
        return (
          <div>
            {props.description.map((el, _i, array) => (
              <span key={uuidv4()} className="paragraph">
                {t(el.key)}{' '}
                {getCardApiValue(el?.value)?.value +
                  getCardApiValue(el?.value)?.unit}{' '}
                {_i < array.length - 1 ? '|' : ''}
              </span>
            ))}
          </div>
        );
      case 'label':
        return (
          <span key={uuidv4()} className="paragraph">
            {t(props.description)}
          </span>
        );
      case 'description':
        return <div className="paragraph">{t(props.description)}</div>;
      case 'keyValue':
        // eslint-disable-next-line no-case-declarations

        // eslint-disable-next-line no-case-declarations
        const result = props.description?.map((el) => {
          return (
            <div>
              <div>
                <span className="paragraph">{t(el.key)} </span>
                <span className="heading5">
                  {' '}
                  {getCardApiValue(el?.value)?.value?.toString() +
                    (getCardApiValue(el?.value)?.unit ||
                      getCardApiValue(el?.value)?.unit_type)}
                </span>
              </div>
            </div>
          );
        });

        return result;
      case 'value+label':
        return (
          <div>
            <span key={uuidv4()} className="paragraph">
              {' '}
              <span className="heading5">
                {cardValue?.value + cardValue?.unit}
              </span>
            </span>
            <span key={uuidv4()} className="paragraph">
              {t(props.description)}
            </span>
          </div>
        );
      case 'label+value':
        if (typeof props.description === 'string') {
          return (
            <div>
              <span key={uuidv4()} className="paragraph">
                {t(props.description)}
              </span>
              <span key={uuidv4()} className="paragraph">
                {' '}
                <span className="heading5">{cardValue?.value}</span>
              </span>
            </div>
          );
        } else if (Array.isArray(props.description)) {
          return (
            <div key={uuidv4()}>
              {props.description.map((el, _i, array) => (
                <div key={uuidv4()}>
                  <span className={`color-hive-greyscale-600`}>
                    <span className="font-flexo-semibold heading5">
                      {t(el.key)}{' '}
                    </span>
                    {getCardApiValue(el?.value)?.value?.toString() +
                      (getCardApiValue(el?.value)?.unit ||
                        getCardApiValue(el?.value)?.unit_type)}
                  </span>
                  {_i < array.length - 1 && <br />}
                </div>
              ))}
            </div>
          );
        }
        break;
      case 'siteType[label+value]':
        if (typeof props.description === 'string') {
          return (
            <div>
              <span key={uuidv4()} className="paragraph">
                {t(
                  props?.[
                    (metaDataApiStore?.selectedEntity as any)?.siteType?.[0]
                      ?.siteType || 'defaul'
                  ]?.description
                )}
              </span>
              <span key={uuidv4()} className="paragraph">
                {' '}
                <span className="heading5">{cardValue?.value}</span>
              </span>
            </div>
          );
        } else if (Array.isArray(props.description)) {
          return (
            <div key={uuidv4()}>
              {props.description?.map((el, _i, array) => (
                <div key={uuidv4()}>
                  <span className={`color-hive-greyscale-600`}>
                    <span className="font-flexo-semibold heading5">
                      {t(el.key)}{' '}
                    </span>
                    {getCardApiValue(el?.value)?.value?.toString() +
                      (getCardApiValue(el?.value)?.unit ||
                        getCardApiValue(el?.value)?.unit_type)}
                  </span>
                  {_i < array.length - 1 && <br />}
                </div>
              ))}
            </div>
          );
        } else if (Object.keys(props?.description)?.length > 0) {
          return (
            <div key={uuidv4()}>
              {props.description?.[
                (metaDataApiStore?.selectedEntity as any)?.siteType?.[0]
                  ?.siteType ||
                  (metaDataApiStore?.selectedEntity as any)?.type ||
                  'default'
              ]?.map((el, _i, array) => (
                <div key={uuidv4()}>
                  <span className={`color-hive-greyscale-600`}>
                    <span className="font-flexo-semibold heading5">
                      {t(el.key)}{' '}
                    </span>
                    {getCardApiValue(el?.value)?.value?.toString() +
                      (getCardApiValue(el?.value)?.unit ||
                        getCardApiValue(el?.value)?.unit_type)}
                  </span>
                  {_i < array.length - 1 && <br />}
                </div>
              ))}
            </div>
          );
        }
        break;

      case '{{trees}}':
        return (
          <div>
            <span>
              {t(props.description.line_1)}
              <br />
              {t(props.description.line_2, { trees: cardValue?.value || 0 })}
            </span>
          </div>
        );
        break;
      case 'trees':
        return (
          <div className="paragraph">
            <span className="paragraph">
              {t(props.description.line_1)}
              <br />
              {t(props.description.line_2)}
              <span
                style={{
                  fontSize: '14px !important',
                  fontWeight: '500 !important',
                }}
                className="heading5"
              >
                {` ${cardValue?.value || ' -.-'}  ${t(
                  'widgets.pollutionCard.trees'
                )} `}
              </span>
              {t(props.description.line_3)}
            </span>
          </div>
        );
      case 'ranking[label+value]':
        return (
          <div>
            <span key={uuidv4()} className="paragraph">
              <span className="heading5"> {t(props.description)} </span>
            </span>
            <span key={uuidv4()} className="paragraph">
              {getSiteRanking()}
            </span>
          </div>
        );
        break;
    }
  };

  return (
    <div
      className={` ${
        props.gradientBorder && styles.InformationCard__GradientBorder
      }`}
      style={{ height: '100%' }}
      key={Math.floor(Math.random() * 100)}
    >
      <div
        className={`${styles.InformationCard} ${
          ENV.VITE_APP_IS_WEB === 'true' && styles.InformationCard__Web
        }`}
      >
        {props.icon && (
          <span
            key={uuidv4()}
            className={`${InformationCard__Icon} ${
              ENV.VITE_APP_IS_WEB === 'true' &&
              styles.InformationCard__Web__Icon
            }`}
          >
            {<IconWrapper iconName={props.icon} />}{' '}
          </span>
        )}{' '}
        {props?.totalValue && (
          <span className={`${InformationCard__Value} `}>
            {' '}
            {getCardValue()}
          </span>
        )}
        <span
          key={uuidv4()}
          className={`${
            props?.detail === false
              ? InformationCard__Label__Disabled
              : InformationCard__Label
          } ${
            ENV.VITE_APP_IS_WEB === 'true' && styles.InformationCard__Web__Label
          } heading4`}
          onClick={() => (props?.detail === false ? undefined : onClickLabel())}
        >
          {props.id === 'pollutionCard' ? (
            <span>
              CO<sub>2</sub>
            </span>
          ) : (
            t(`widgets.${props.id}.title`)
          )}
          <IconWrapper
            className={'InformationCard__Chevron'}
            iconName="chevron-right"
          />
        </span>
        <span
          className={`${InformationCard__Detail} paragraph ${
            ENV.VITE_APP_IS_WEB === 'true' &&
            styles.InformationCard__Web__Detail
          }`}
        >
          {getCardDetail()}
        </span>
      </div>
    </div>
  );
}

export default InformationCard;
