//save as site: {
// 1day:{}
//1hour:{}
// }
//event driven development
import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  UserContext,
  CalendarContext,
  KpiApiContext,
  KpiDataTypes,
  MetaDataApiContext,
  AlertContext,
  AlertTypes,
  UserTypes,
} from '@flexo/providers';
import {
  FirebaseService,
  formatDate,
  formatEndOfWeek,
  formatStringToDate,
  getFormattedEndOfMonth,
  getFormattedNextDay,
  getLastDayOfYear,
  getTimeGranularity,
  getTimeGranularityOverview,
  httpService,
} from '@flexo/general';
import { is } from 'date-fns/locale';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

let round = 0;
let isLoading = false;

export const KpiMemberResolver = () => {
  const { t } = useTranslation();

  const { setUserStore } = useContext(UserContext);
  const { calendarStore } = useContext(CalendarContext);
  const { setKpiApiStore } = useContext(KpiApiContext);
  const { setAlertStore } = useContext(AlertContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);

  const { selectedDate, selectedWeek, currentlySelectedDateType } =
    calendarStore;

  const dates = {
    day: {
      dateFrom: formatDate(selectedDate),
      dateTo: getFormattedNextDay(selectedDate),
    },
    week: {
      dateFrom: formatDate(selectedWeek.startDate as Date),
      dateTo: formatEndOfWeek(selectedWeek.endDate as Date),
    },
    month: {
      dateFrom: formatDate(selectedDate),
      dateTo: formatStringToDate(
        getFormattedEndOfMonth(new Date(selectedDate))
      ),
    },
    year: {
      dateFrom: formatDate(selectedDate),
      dateTo: formatStringToDate(getLastDayOfYear(selectedDate)),
    },
  };

  async function init() {
    isLoading = true;

    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        },
      });

      return;
    }

    if (!metaDataApiStore?.selectedEntityId) { return }
    if (!metaDataApiStore?.sortedEntities) { return }

    const sites = (metaDataApiStore?.entities as any)?.site
      ?.filter((site: any) => site?.memberId === metaDataApiStore?.selectedEntityId)
      ?.map((site) => site?.siteId) || [];
    
    const [kpiMemberDataOverview, kpiMemberDataDetail] = await Promise.all([
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/grouped`,
          data: {
            ...dates[currentlySelectedDateType],
            //add null: null to test the error
            timeGranularity: getTimeGranularityOverview(
              currentlySelectedDateType
            ),
            siteId: sites,
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),

      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/grouped`,
          data: {
            ...dates[currentlySelectedDateType],
            timeGranularity: getTimeGranularity(
              currentlySelectedDateType,
              null
            ),
            siteId: sites,
            locale: ENV?.VITE_APP_LOCALE || '=it-IT',
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),
    ]);

    if (kpiMemberDataOverview && kpiMemberDataDetail) {
      setKpiApiStore({
        type: KpiDataTypes.SetKpiDataMember,
        payload: {
          value: {
            '1day': kpiMemberDataOverview,
            '1hour': kpiMemberDataDetail,
          },
        },
      });
    }

    isLoading = false;
    round++;
  }

  // FOR MELINA TO TEST
  useEffect(() => {
    if (round === 0 && metaDataApiStore.selectedEntityId) {
      init();
    }
  }, [metaDataApiStore?.selectedEntityId]);

  useEffect(() => {
    if (round > 0 && isLoading === false) {
      init();
    }
  }, [calendarStore.selectedDate]);
  
  return <></>;
};
