
import React, { useContext, useEffect, useState, } from 'react';

import styles from './header-web.module.scss';
import {  MetaDataApiContext, ModulesContext } from '@flexo/providers';
import { useTranslation } from 'react-i18next';

import { default as CommunitiesNavigation } from './header-web-communities';
import { default as CommunityNavigation } from './header-web-community';
import { default as MemberNavigation } from './header-web-member';
import { default as MemberDataVisNavigation } from './header-web-data-vis-member';
import { default as SiteOverviewNavigation } from './header-web-site-overview';
import { default as SiteNavigation } from './header-web-site';
import { default as CommunityDataVisNavigation } from './header-web-data-vis-community';

import './header-web.module.scss';

const ENV = (import.meta as any).env;


const {
  Header__Web,
  Navigation,
} = styles;

function SimpleNavigation(props: any) {
  
  const { t } = useTranslation()
  

  return (
    <div className={Navigation}>
      <strong>{ t(`widgets.header.${props.label}`)}</strong>
    </div>
  );
}

function HeaderWeb(props) {


  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null } = props;
  const { t } = useTranslation();
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { modulesStore, setModulesStore } = useContext(ModulesContext);
  const [ activeModule, setActiveModule ] = useState<string|null>(null);


  useEffect(() => {

    setActiveModule(null)

    const _activeModuleName = modulesStore?.modules?.find((module: any) => module.visible === true)?.name || null;

    setActiveModule(_activeModuleName || ENV.VITE_APP_FIRST_PAGE)
    
  }, [modulesStore?.modules])

  return (
    <div className={Header__Web}>
      {
          activeModule && (() => {

            switch( activeModule ) {
              case 'communities':
                return <CommunitiesNavigation {...props} />
                case 'community':
                return <CommunityNavigation {...props} />
                case 'member':
                  return <MemberNavigation {...props} />
                case 'member-energy':
                case 'member-economics':
                  return <MemberDataVisNavigation {...props} />
                case 'site':
                  return <SiteOverviewNavigation {...props} />
                case 'site-energy':
                case 'site-economics':
                  return <SiteNavigation {...props} />
                case 'community-energy':
                case 'community-economics':
                  return <CommunityDataVisNavigation {...props} />
                default:
                  return <SimpleNavigation {...props} />
            }

          })()
        }
    </div>
  );
}

export default React.memo(HeaderWeb);
