export default {
  primary: '#009E62',
  secondary: '#009E62',
  tertiary: '#009E62',
  greyscale: '#838b93',
  consumption: '#FCCD00',
  production: '#95B849',
  economics: '#056B69',
  'self-consumption': '#8BB8D8',
  negative: '#DB2F16',
  success: '#99CC00',
  grey: '#838B93',
  pureWhite: '#ffffff',
  battery: '#A892BD',
  shared: '#4C81B3',
  gridColor: '#CCCED0',
  consumptionShared: '#FCCD0060',
  productionShared: '#AAC66C60',
  consumptionGrid: '#FFEB94',
  productionGrid: '#BFD491',
};
